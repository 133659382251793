import { Box, Card, Typography } from "@mui/material";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import "./index.css";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const MyCarousel = ({ member }) => {
  const navigate = useNavigate();

  const teamDetailsPage = (value) => {
    localStorage.setItem("selectedMember", JSON.stringify(value));
    navigate("/TeamDetails");
  };

  return (
    <div className="container">
      <div>
        <Carousel responsive={responsive} className="custom-carousel">
          {member &&
            member.map((val, id) => {
              return (
                <a href="/TeamDetails" key={id}>
                  <Card
                    sx={{
                      padding: "10px",
                      margin: "0 10px",
                    }}
                    onClick={() => teamDetailsPage(val)}
                  >
                    <img src={val.image} height={"100%"} width={"100%"} />
                    <Box
                      sx={{
                        mt: 1,
                      }}
                    >
                      <Typography fontSize={20} fontWeight={700}>
                        {val.name}
                      </Typography>
                      <Typography>{val.role}</Typography>
                    </Box>
                  </Card>
                </a>
              );
            })}
        </Carousel>
      </div>
    </div>
  );
};

export default MyCarousel;
