import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./index.css";
const BlogDetails = () => {
  let location = useLocation();

  const [pageData, setPageData] = useState(null);

  console.log("pageData", pageData);

  useEffect(() => {
    if (location.state) {
      setPageData(location.state.value);
    }
  }, [location.state]);

  return (
    <>
      <div className="">
        <Box className="mil-content">
          <div id="swupMain" className="mil-main-transition ">
            <div className="bg-black mil-dark-bg page_alignment ">
              <div className="mil-inner-banner mainpage_alignment">
                <div className="mi-invert-fix">
                  <div className="mil-banner-content mil-up">
                    <div className="mil-animation-frame">
                      <div
                        className="mil-animation mil-position-1 mil-scale"
                        data-value-1="7"
                        data-value-2="1.6"
                        style={{
                          transform: "translate3d(0px, 0px, 0px) scale(2, 2)",
                        }}
                      >
                        <div className="mil-dodecahedron">
                          {[...Array(12)].map((_, index) => (
                            <div key={index} className="mil-pentagon">
                              {[...Array(5)].map((_, index) => (
                                <div key={index}></div>
                              ))}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="container section_alignment">
                      <ul
                        className="mil-breadcrumbs mil-light mil-mb-60"
                        style={{ padding: "0" }}
                      >
                        <li>
                          <a href="/">Homepage</a>
                        </li>
                        <li>
                          <a href="#">Blogs Detail's</a>
                        </li>
                      </ul>
                      <h1
                        className="mil-muted mil-mb-60"
                        style={{ color: "#ffd94a" }}
                      >
                        Reality Scale's{" "}
                        <span className="mil-thins" style={{ color: "white" }}>
                          Pixel-Perfect
                        </span>
                        <br /> Marketing
                        <span className="mil-thins" style={{ color: "white" }}>
                          {" "}
                          Evolution
                        </span>
                      </h1>
                      <a
                        href="#"
                        className="mil-link mil-accent mil-arrow-place mil-down-arrow"
                      ></a>
                    </div>{" "}
                    <div className="container section_alignment">
                      {/* introduction start  */}
                      <Box>
                        <Typography
                          color={"#ffd94a"}
                          sx={{
                            fontSize: "2.2rem",
                            fontWeight: 800,
                          }}
                        >
                          {pageData && pageData.heading}
                        </Typography>{" "}
                        <Typography
                          color={"#fff"}
                          sx={{
                            fontSize: "17px",
                            fontWeight: 100,
                            marginTop: "8px",
                          }}
                        >
                          {pageData && pageData.headparaone}
                        </Typography>{" "}
                        <Typography
                          color={"#fff"}
                          sx={{
                            fontSize: "17px",
                            fontWeight: 100,
                            marginTop: "8px",
                          }}
                        >
                          {pageData && pageData.headonetwo}
                        </Typography>{" "}
                        <Typography
                          color={"#fff"}
                          sx={{
                            fontSize: "17px",
                            fontWeight: 100,
                            marginTop: "8px",
                          }}
                        >
                          {pageData && pageData.headonethree}
                        </Typography>
                      </Box>
                      {/* introduction send  */}
                      {/* Digital marketing start  */}
                      <Box
                        sx={{
                          marginTop: "3rem ",
                        }}
                      >
                        <Typography
                          color={"#ffd94a"}
                          sx={{
                            fontSize: "2.2rem",
                            fontWeight: 800,
                          }}
                        >
                          {pageData && pageData.headingtwo}
                        </Typography>{" "}
                        <Box
                          sx={{
                            my: 8,
                          }}
                        >
                          <img
                            src={pageData && pageData.chart}
                            height={"100%"}
                            width={"100%"}
                          />
                        </Box>
                        <Typography
                          color={"#fff"}
                          sx={{
                            fontSize: "17px",
                            fontWeight: 100,
                            marginTop: "8px",
                          }}
                        >
                          {pageData && pageData.currentparaone}
                        </Typography>{" "}
                      </Box>
                      {/* Digital marketing end */}
                      {/* Evolution start */}
                      <Box
                        sx={{
                          mt: 5,
                        }}
                      >
                        <Typography
                          color={"#ffd94a"}
                          sx={{
                            fontSize: "2.2rem",
                            fontWeight: 800,
                          }}
                        >
                          {pageData && pageData.headingthree}
                        </Typography>{" "}
                        <Typography
                          color={"#fff"}
                          sx={{
                            fontSize: "17px",
                            fontWeight: 100,
                            marginTop: "8px",
                          }}
                        >
                          {pageData && pageData.digitalmarketingparaone}
                        </Typography>{" "}
                        <Box
                          sx={{
                            my: 4,
                          }}
                        >
                          <ul
                            style={{
                              color: "#fff",
                            }}
                          >
                            {pageData &&
                              pageData.marketinglistone &&
                              pageData.marketinglistone.map((val, id) => {
                                return (
                                  <li>
                                    <Typography
                                      color={"#fff"}
                                      sx={{
                                        fontSize: "1rem",
                                        fontWeight: 300,
                                        my: 2,
                                      }}
                                    >
                                      {val.list}
                                    </Typography>
                                  </li>
                                );
                              })}
                          </ul>
                        </Box>
                        <Typography
                          color={"#fff"}
                          sx={{
                            fontSize: "17px",
                            fontWeight: 100,
                            marginTop: "8px",
                          }}
                        >
                          {pageData && pageData.marketingparatwo}
                        </Typography>{" "}
                        <Box
                          sx={{
                            my: 4,
                          }}
                        >
                          <ul
                            style={{
                              color: "#fff",
                            }}
                          >
                            {pageData &&
                              pageData.marketinglisttwo &&
                              pageData.marketinglisttwo.map((val, id) => {
                                return (
                                  <li>
                                    <Typography
                                      color={"#fff"}
                                      sx={{
                                        fontSize: "1rem",
                                        fontWeight: 300,
                                        my: 2,
                                      }}
                                    >
                                      {val.list}
                                    </Typography>
                                  </li>
                                );
                              })}
                          </ul>
                        </Box>
                        <Typography
                          color={"#fff"}
                          sx={{
                            fontSize: "17px",
                            fontWeight: 100,
                            marginTop: "8px",
                          }}
                        >
                          {pageData && pageData.marketingparathree}
                        </Typography>
                      </Box>
                      {/* Evolution end */}
                      {/* Technology start */}
                      <Box
                        sx={{
                          mt: "3rem",
                        }}
                      >
                        <Typography
                          color={"#ffd94a"}
                          sx={{
                            fontSize: "2.2rem",
                            fontWeight: 800,
                          }}
                        >
                          {pageData && pageData.techheading}
                        </Typography>{" "}
                        <Typography
                          color={"#fff"}
                          sx={{
                            fontSize: "17px",
                            fontWeight: 100,
                            marginTop: "8px",
                          }}
                        >
                          {pageData && pageData.techparaone}
                        </Typography>{" "}
                        <Typography
                          color={"#fff"}
                          sx={{
                            fontSize: "17px",
                            fontWeight: 100,
                            marginTop: "8px",
                          }}
                        >
                          {pageData && pageData.techparatwo}
                        </Typography>{" "}
                        <Typography
                          color={"#fff"}
                          sx={{
                            fontSize: "17px",
                            fontWeight: 100,
                            marginTop: "8px",
                          }}
                        >
                          {pageData && pageData.techparathree}
                        </Typography>
                      </Box>
                      {/* Technology ends */}
                      {/* landscape start */}
                      <Box
                        sx={{
                          mt: 5,
                        }}
                      >
                        <Typography
                          color={"#ffd94a"}
                          sx={{
                            fontSize: "2.2rem",
                            fontWeight: 800,
                          }}
                        >
                          {pageData && pageData.Landscapeheading}
                        </Typography>{" "}
                        <Typography
                          color={"#fff"}
                          sx={{
                            fontSize: "17px",
                            fontWeight: 100,
                            marginTop: "8px",
                          }}
                        >
                          {pageData && pageData.landscapepara}
                        </Typography>{" "}
                        <Box
                          sx={{
                            my: 4,
                          }}
                        >
                          <ul
                            style={{
                              color: "#fff",
                            }}
                          >
                            {pageData &&
                              pageData.landscapelist &&
                              pageData.landscapelist.map((val, id) => {
                                return (
                                  <li>
                                    <Box
                                      sx={{
                                        display: "grid",
                                      }}
                                    >
                                      <Typography
                                        color={"#fff"}
                                        sx={{
                                          fontSize: "2rem",
                                          fontWeight: 500,
                                          my: 2,
                                        }}
                                      >
                                        {val.heading}
                                      </Typography>{" "}
                                      <Typography
                                        color={"#fff"}
                                        sx={{
                                          fontSize: "1rem",
                                          fontWeight: 300,
                                        }}
                                      >
                                        {val.para}
                                      </Typography>
                                    </Box>
                                  </li>
                                );
                              })}
                          </ul>
                        </Box>
                        <Typography
                          color={"#fff"}
                          sx={{
                            fontSize: "17px",
                            fontWeight: 100,
                            marginTop: "8px",
                          }}
                        >
                          {pageData && pageData.landscapeparatwo}
                        </Typography>{" "}
                      </Box>
                      {/* landscape ends */}
                      {/* The Path Forward: Balancing Art and Science with Reality Scale start */}
                      <Box
                        sx={{
                          mt: 5,
                        }}
                      >
                        <Typography
                          color={"#ffd94a"}
                          sx={{
                            fontSize: "2.2rem",
                            fontWeight: 800,
                          }}
                        >
                          {pageData && pageData.pathforward}
                        </Typography>{" "}
                        <Typography
                          color={"#fff"}
                          sx={{
                            fontSize: "17px",
                            fontWeight: 100,
                            marginTop: "8px",
                          }}
                        >
                          {pageData && pageData.pathforwardparaone}
                        </Typography>{" "}
                        <Typography
                          color={"#fff"}
                          sx={{
                            fontSize: "17px",
                            fontWeight: 100,
                            marginTop: "8px",
                          }}
                        >
                          {pageData && pageData.pathforward2}
                        </Typography>{" "}
                        <Box
                          sx={{
                            my: 4,
                          }}
                        >
                          <ul
                            style={{
                              color: "#fff",
                            }}
                          >
                            {pageData &&
                              pageData.pathforwardlist &&
                              pageData.pathforwardlist.map((val, id) => {
                                return (
                                  <li>
                                    <Typography
                                      color={"#fff"}
                                      sx={{
                                        fontSize: "1rem",
                                        fontWeight: 300,
                                        my: 2,
                                      }}
                                    >
                                      {val.list}
                                    </Typography>
                                  </li>
                                );
                              })}
                          </ul>
                        </Box>
                        <Typography
                          color={"#fff"}
                          sx={{
                            fontSize: "17px",
                            fontWeight: 100,
                            marginTop: "8px",
                          }}
                        >
                          {pageData && pageData.pathforwardparatwo}
                        </Typography>{" "}
                        <Typography
                          color={"#fff"}
                          sx={{
                            fontSize: "17px",
                            fontWeight: 100,
                            marginTop: "8px",
                          }}
                        >
                          {pageData && pageData.pathforwardparathree}
                        </Typography>{" "}
                        <Typography
                          color={"#fff"}
                          sx={{
                            fontSize: "17px",
                            fontWeight: 100,
                            marginTop: "8px",
                          }}
                        >
                          {pageData && pageData.pathforwardparafour}
                        </Typography>{" "}
                        <Box
                          sx={{
                            my: 4,
                          }}
                        >
                          <ul
                            style={{
                              color: "#fff",
                            }}
                          >
                            {pageData &&
                              pageData.pathforwardlisttwo &&
                              pageData.pathforwardlisttwo.map((val, id) => {
                                return (
                                  <li key={id}>
                                    <Box
                                      sx={{
                                        display: "grid",
                                      }}
                                    >
                                      <Typography
                                        color={"#fff"}
                                        sx={{
                                          fontSize: "2rem",
                                          fontWeight: 500,
                                          my: 2,
                                        }}
                                      >
                                        {val.heading}
                                      </Typography>{" "}
                                      <Typography
                                        color={"#fff"}
                                        sx={{
                                          fontSize: "1rem",
                                          fontWeight: 300,
                                        }}
                                      >
                                        {val.para}
                                      </Typography>
                                    </Box>
                                  </li>
                                );
                              })}
                          </ul>
                        </Box>
                        <Typography
                          color={"#fff"}
                          sx={{
                            fontSize: "17px",
                            fontWeight: 100,
                            marginTop: "8px",
                          }}
                        >
                          {pageData && pageData.pathforwardparafifth}
                        </Typography>{" "}
                      </Box>
                      {/* The Path Forward: Balancing Art and Science with Reality Scale ends */}
                      {/* Reality Scale in Action start */}
                      <Box
                        sx={{
                          mt: 5,
                        }}
                      >
                        <Typography
                          color={"#ffd94a"}
                          sx={{
                            fontSize: "2.2rem",
                            fontWeight: 800,
                          }}
                        >
                          {pageData && pageData.actionmainheading}
                        </Typography>
                        <Typography
                          color={"#fff"}
                          sx={{
                            fontSize: "1.5rem",
                            fontWeight: 500,
                          }}
                        >
                          {pageData && pageData.actionheading}
                        </Typography>
                        <Typography
                          color={"#fff"}
                          sx={{
                            fontSize: "17px",
                            fontWeight: 100,
                            marginTop: "12px",
                          }}
                        >
                          {pageData && pageData.actionparaone}
                        </Typography>{" "}
                        <Typography
                          color={"#fff"}
                          sx={{
                            fontSize: "17px",
                            fontWeight: 100,
                            marginTop: "20px",
                          }}
                        >
                          {pageData && pageData.actionshortheading}
                        </Typography>{" "}
                        <Box
                          sx={{
                            my: 3,
                          }}
                        >
                          <ul
                            style={{
                              color: "#fff",
                            }}
                          >
                            {pageData &&
                              pageData.actionlist &&
                              pageData.actionlist.map((val, id) => {
                                return (
                                  <li>
                                    <Typography
                                      color={"#fff"}
                                      sx={{
                                        fontSize: "1rem",
                                        fontWeight: 300,
                                        my: 2,
                                      }}
                                    >
                                      {val.list}
                                    </Typography>
                                  </li>
                                );
                              })}
                          </ul>
                        </Box>
                        <Typography
                          color={"#fff"}
                          sx={{
                            fontSize: "17px",
                            fontWeight: 100,
                            marginTop: "8px",
                          }}
                        >
                          {pageData && pageData.actionparatwo}
                        </Typography>{" "}
                        <Typography
                          color={"#fff"}
                          sx={{
                            fontSize: "25px",
                            fontWeight: 100,
                            marginTop: "18px",
                          }}
                        >
                          {pageData && pageData.actionapplicationheading}
                        </Typography>{" "}
                        <Box>
                          <ul
                            style={{
                              color: "#fff",
                            }}
                          >
                            {pageData &&
                              pageData.actionapplicationlist &&
                              pageData.actionapplicationlist.map((val, id) => {
                                return (
                                  <li key={id}>
                                    <Box
                                      sx={{
                                        display: "grid",
                                      }}
                                    >
                                      <Typography
                                        color={"#fff"}
                                        sx={{
                                          fontSize: "2rem",
                                          fontWeight: 500,
                                          my: 2,
                                        }}
                                      >
                                        {val.heading}
                                      </Typography>{" "}
                                      <Typography
                                        color={"#fff"}
                                        sx={{
                                          fontSize: "1rem",
                                          fontWeight: 300,
                                        }}
                                      >
                                        {val.para}
                                      </Typography>
                                    </Box>
                                  </li>
                                );
                              })}
                          </ul>
                        </Box>
                        <Typography
                          color={"#fff"}
                          sx={{
                            fontSize: "17px",
                            fontWeight: 100,
                            marginTop: "20px",
                          }}
                        >
                          {pageData && pageData.actioninnovativeheading}
                        </Typography>{" "}
                        <Box
                          sx={{
                            my: 3,
                          }}
                        >
                          <ul
                            style={{
                              color: "#fff",
                            }}
                          >
                            {pageData &&
                              pageData.actioninnovativelist &&
                              pageData.actioninnovativelist.map((val, id) => {
                                return (
                                  <li>
                                    <Typography
                                      color={"#fff"}
                                      sx={{
                                        fontSize: "1rem",
                                        fontWeight: 300,
                                        my: 2,
                                      }}
                                    >
                                      {val.list}
                                    </Typography>
                                  </li>
                                );
                              })}
                          </ul>
                        </Box>
                        <Typography
                          color={"#fff"}
                          sx={{
                            fontSize: "17px",
                            fontWeight: 100,
                            marginTop: "8px",
                          }}
                        >
                          {pageData && pageData.actionparathree}
                        </Typography>{" "}
                      </Box>
                      {/* Reality Scale in Action ends */}
                      {/* Conclusion start */}
                      <Box
                        sx={{
                          mt: 5,
                        }}
                      >
                        <Typography
                          color={"#ffd94a"}
                          sx={{
                            fontSize: "2.2rem",
                            fontWeight: 800,
                          }}
                        >
                          {pageData && pageData.conclusiontext}
                        </Typography>{" "}
                        <Typography
                          color={"#fff"}
                          sx={{
                            fontSize: "17px",
                            fontWeight: 100,
                            marginTop: "12px",
                          }}
                        >
                          {pageData && pageData.conclusionparaone}
                        </Typography>{" "}
                        <Typography
                          color={"#fff"}
                          sx={{
                            fontSize: "17px",
                            fontWeight: 100,
                            marginTop: "12px",
                          }}
                        >
                          {pageData && pageData.conclusionparatwo}
                        </Typography>{" "}
                        <Typography
                          color={"#fff"}
                          sx={{
                            fontSize: "17px",
                            fontWeight: 100,
                            marginTop: "12px",
                          }}
                        >
                          {pageData && pageData.conclusionparathree}
                        </Typography>{" "}
                      </Box>
                      {/* Conclusion ends */}
                      {/* Conclusion start */}
                      <Box
                        sx={{
                          mt: 5,
                        }}
                      >
                        <Typography
                          color={"#ffd94a"}
                          sx={{
                            fontSize: "2.2rem",
                            fontWeight: 800,
                          }}
                        >
                          Source
                        </Typography>{" "}
                        <ul
                          style={{
                            color: "#fff",
                          }}
                        >
                          <a
                            href="https://skillfloor.medium.com/the-evolution-of-digital-marketing-from-traditional-to-modern-strategies-d85dea07f0cf"
                            style={{
                              color: "#fff",
                            }}
                          >
                            <li>SkillFloor</li>
                          </a>
                          <a
                            href="https://thecorrespondent.com/100/the-new-dot-com-bubble-is-here-its-called-online-advertising"
                            style={{
                              color: "#fff",
                            }}
                          >
                            <li>thecorrespondent</li>
                          </a>
                        </ul>
                        <Typography
                          color={"#000"}
                          sx={{
                            fontSize: "17px",
                            fontWeight: 100,
                            marginTop: "12px",
                          }}
                        >
                          {pageData && pageData.conclusionparathree}
                        </Typography>{" "}
                      </Box>
                      {/* Conclusion ends */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </div>
    </>
  );
};

export default BlogDetails;
