import disneyslide4 from "../../assests/carimages/Disney Pijama Ride VR_Game (1).webp";
import disneyslide5 from "../../assests/carimages/Disney Pijama Ride VR_Game (4).webp";
import {
  default as disneyslide3,
  default as image,
} from "../../assests/carimages/disney_image1.webp";
import olympic1 from "../../assests/carimages/Olymics Bmx Ar Gameplay-1 (1).webp";
import olympic2 from "../../assests/carimages/Olymics Bmx Ar Gameplay-1 (2).webp";
import olympic3 from "../../assests/carimages/Olymics Bmx Ar Gameplay-1 (4).webp";
import olympic from "../../assests/carimages/Olymics Bmx Ar Gameplay-1 (5).webp";
import youtholympic from "../../assests/carimages/olympic_homepage.webp";
import nexon2 from "../../assests/carimages/pexels-helen-alp-46790226-27779253.jpg";
import nexon1 from "../../assests/carimages/pexels-helen-alp-46790226-27779257.jpg";
import nexon from "../../assests/carimages/tatanexon.webp";
import disneyslide2 from "../../assests/images/work/disneyslider/disneyslide2.png";
import mahindra1 from "../../assests/images/work/mahindra/back.jpg";
import mahindra from "../../assests/images/work/mahindra/light.jpg";
import mahindra2 from "../../assests/images/work/mahindra/opengate.jpg";
import mahindra3 from "../../assests/images/work/mahindra/wheel.jpg";
import screencraft from "../../assests/carimages/screencraft.png";
let NewData = {
  tabData: [
    {
      label: "VR/AR",
      slides: [
        {
          id: 0,
          img: image,
          thumbnail: image,
          video:
            "https://www.youtube.com/embed/deu0PnGsZgg?si=cWgTyLAT4gT3q6Tt",
          title: "VR/AR",
          description: "Description for VR/AR Image 1",
          content: "Disney Pyjama Ride",
          heading: "Disney Pyjama Ride",
          client: "Disney",
          subheading:
            "Rhythm-based VR game synchronized with live music. Uses MIDI integration for real-time note mapping, creating an interactive concert experience in virtual reality.",
          productDetails: {
            bannerImage: disneyslide2,
            brand: "",
            slider1Image: [
              {
                img: disneyslide2,
              },
              {
                img: disneyslide3,
              },
              {
                img: disneyslide4,
              },
              {
                img: disneyslide5,
              },
            ],
            paraHeading: "Disney Pyjama Ride",
            heading1: "Pyjama Ride: An Exciting VR Adventure for Kids",
            heading2: "Project Overview",
            para1:
              "One of our most thrilling projects to date, Pyjama Ride is an immersive Virtual Reality experience designed for a popular children's show. This project showcases our expertise in creating engaging, child-friendly VR content while pushing the boundaries of real-time gameplay and dynamic environments.",
            para2:
              "Pyjama Ride puts young players in control of a sledge racing down a sprawling mountain landscape. This multi-level adventure mimics the excitement of a roller coaster ride, taking children through various thrilling scenarios and unexpected events.",
            impact: "Impact",
            impactberief:
              "Pyjama Ride demonstrates our ability to create engaging, age-appropriate content that sparks imagination in young users. This project stands as a testament to our commitment to innovation and expertise in crafting unforgettable virtual experiences.",

            featureheading: "Key Feature",
            technical: "Technical Achievements",
            technicalsubheading:
              "Our team overcame several challenges to bring this project to life:",
            featurelist: [
              {
                list: "Intuitive controls for young players",
              },
              {
                list: "Multiple levels with varied terrain and challenges",
              },
              {
                list: "Dynamic events (e.g., collapsing rocks, flowing lava)",
              },
              {
                list: "Real-time gameplay recording capabilities",
              },
            ],

            technicallist: [
              {
                head: "Dynamic Track System",
                list: "We developed a system allowing for custom tracks with precise control over sledge speed and track shape.",
              },
              {
                head: "Real-time Recording",
                list: "We implemented a system for capturing the VR gameplay in real-time.",
              },
              {
                head: "Expansive, Seamless World",
                list: "Our developers crafted a vast, multi-level map providing a continuous sliding experience.",
              },
              {
                head: "Dynamic Event Triggering",
                list: "We created a flexible system for triggering real-time events during gameplay.",
              },
              {
                head: "VR Optimization",
                list: "The entire experience was optimized for smooth VR performance.",
              },
            ],
            slider2: [
              {
                img: mahindra,
              },
              {
                img: mahindra1,
              },
              {
                img: mahindra2,
              },
              {
                img: mahindra3,
              },
            ],
          },
        },
        {
          id: 1,
          img: youtholympic,
          thumbnail: youtholympic,
          video:
            "https://www.youtube.com/embed/VHwrnb0L77E?si=grf0gSoRb209BQdT",
          title: "VR/AR",
          description: "Description for VR/AR Image 2",
          content: "Youth Olympics Project",
          heading: "Youth Olympics Project",
          subheading:
            "India's first VR car configurator. Offers real-time customization of vehicle features, colors, and accessories in a fully immersive virtual environment.",
          productDetails: {
            bannerImage: youtholympic,
            client: "Disney",
            brand: "",
            slider1Image: [
              {
                img: olympic,
              },
              {
                img: olympic1,
              },
              {
                img: olympic2,
              },
              {
                img: olympic3,
              },
            ],
            paraHeading: "Youth Olympics Project",
            heading1: "BMX Olympic Showdown: AR Racing Reimagined",
            heading2: "Project Overview",
            para1:
              "BMX Olympic Showdown, an exhilarating multiplayer Augmented Reality racing game developed for the Winter Youth Olympics show floor. This project exemplifies our ability to create quick and fun experiences that engage and excite users in high-stakes environments using already existing technologies and at rapid speed.",
            para2:
              "The game was made to transform any floor into a virtual racing arena, allowing multiple players to compete in thrilling BMX races using iPad devices. The game combines the excitement of BMX racing with the immersive capabilities of XR and gives users an easy and competitive experience.",
            impact: "Impact",
            impactberief:
              "Olympic AR BMX demonstrates our capability to create engaging, accessible AR content for large-scale events rapidly. The game was enjoyed by thousands of participants during the Youth Olympics, bringing the excitement of BMX racing to life through the innovative use of existing XR technologies. This project showcases our expertise in swift development of impactful AR solutions that perform flawlessly in demanding, high-profile environments.",

            featureheading: "Key Feature",
            technical: "Technical Achievements",
            technicalsubheading:
              "Our team leveraged existing technologies to deliver this project rapidly:",
            featurelist: [
              {
                list: "Seamless multiplayer connectivity for real-time racing",
              },
              {
                list: "Intuitive controls optimised for iPad devices",
              },
              {
                list: "AR-powered course overlay on any physical floor",
              },
              {
                list: "High-quality animations and visual effects",
              },
              {
                list: "Quick-to-learn, challenging-to-master gameplay",
              },
            ],

            technicallist: [
              {
                head: "Efficient Multiplayer System:",
                list: "We utilised web sockets for seamless device connectivity, enabling multiple race sessions to run simultaneously on the show floor.",
              },
              {
                head: "Simple AR Synchronization:",
                list: "Our team implemented a straightforward AR scanning system to sync the game environment across all connected devices quickly.",
              },
              {
                head: "Low-Latency Action Communication:",
                list: " We created a streamlined system for transmitting and synchronising player actions across devices in real time.",
              },
              {
                head: "Flexible Session Management:",
                list: "Our solution allowed for easy setup of multiplayer sessions using iPads, facilitating quick turnover between participant groups",
              },
              {
                head: "Optimized AR Performance:",
                list: "The experience was fine-tuned to deliver smooth, engaging visuals and animations on iPad devices within a short development timeframe.",
              },
            ],
            slider2: [
              {
                img: mahindra,
              },
              {
                img: mahindra1,
              },
              {
                img: mahindra2,
              },
              {
                img: mahindra3,
              },
            ],
          },
        },
        {
          id: 1,
          img: youtholympic,
          thumbnail: youtholympic,
          video:
            "https://www.youtube.com/embed/VHwrnb0L77E?si=grf0gSoRb209BQdT",
          title: "VR/AR",
          description: "Description for VR/AR Image 2",
          content: "Mahindra Project",
          heading: "Youth Olympics Project",
          subheading:
            "India's first VR car configurator. Offers real-time customization of vehicle features, colors, and accessories in a fully immersive virtual environment.",
          productDetails: {
            bannerImage: youtholympic,
            client: "Disney",
            brand: "",
            slider1Image: [
              {
                img: olympic,
              },
              {
                img: olympic1,
              },
              {
                img: olympic2,
              },
              {
                img: olympic3,
              },
            ],
            paraHeading: "Youth Olympics Project",
            heading1: "BMX Olympic Showdown: AR Racing Reimagined",
            heading2: "Project Overview",
            para1:
              "BMX Olympic Showdown, an exhilarating multiplayer Augmented Reality racing game developed for the Winter Youth Olympics show floor. This project exemplifies our ability to create quick and fun experiences that engage and excite users in high-stakes environments using already existing technologies and at rapid speed.",
            para2:
              "The game was made to transform any floor into a virtual racing arena, allowing multiple players to compete in thrilling BMX races using iPad devices. The game combines the excitement of BMX racing with the immersive capabilities of XR and gives users an easy and competitive experience.",
            impact: "Impact",
            impactberief:
              "Olympic AR BMX demonstrates our capability to create engaging, accessible AR content for large-scale events rapidly. The game was enjoyed by thousands of participants during the Youth Olympics, bringing the excitement of BMX racing to life through the innovative use of existing XR technologies. This project showcases our expertise in swift development of impactful AR solutions that perform flawlessly in demanding, high-profile environments.",

            featureheading: "Key Feature",
            technical: "Technical Achievements",
            technicalsubheading:
              "Our team leveraged existing technologies to deliver this project rapidly:",
            featurelist: [
              {
                list: "Seamless multiplayer connectivity for real-time racing",
              },
              {
                list: "Intuitive controls optimised for iPad devices",
              },
              {
                list: "AR-powered course overlay on any physical floor",
              },
              {
                list: "High-quality animations and visual effects",
              },
              {
                list: "Quick-to-learn, challenging-to-master gameplay",
              },
            ],

            technicallist: [
              {
                head: "Efficient Multiplayer System:",
                list: "We utilised web sockets for seamless device connectivity, enabling multiple race sessions to run simultaneously on the show floor.",
              },
              {
                head: "Simple AR Synchronization:",
                list: "Our team implemented a straightforward AR scanning system to sync the game environment across all connected devices quickly.",
              },
              {
                head: "Low-Latency Action Communication:",
                list: " We created a streamlined system for transmitting and synchronising player actions across devices in real time.",
              },
              {
                head: "Flexible Session Management:",
                list: "Our solution allowed for easy setup of multiplayer sessions using iPads, facilitating quick turnover between participant groups",
              },
              {
                head: "Optimized AR Performance:",
                list: "The experience was fine-tuned to deliver smooth, engaging visuals and animations on iPad devices within a short development timeframe.",
              },
            ],
            slider2: [
              {
                img: mahindra,
              },
              {
                img: mahindra1,
              },
              {
                img: mahindra2,
              },
              {
                img: mahindra3,
              },
            ],
          },
        },
      ],
    },
    {
      label: "Automotive",
      slides: [
        // {
        //   id: 0,
        //   img: nexon,
        //   thumbnail: nexon,
        //   title: "Automotive",
        //   description: "Description for Automotive Image 1",
        //   video:
        //     "https://www.youtube.com/embed/deu0PnGsZgg?si=cWgTyLAT4gT3q6Tt",
        //   content: "Demo Car Configurator",
        //   heading: "Demo Car Configurator",
        //   subheading:
        //     "MR experience for auto shows. Overlays virtual features on a physical car, allowing exploration of internal components and hidden features through a headset.",
        //   productDetails: {
        //     bannerImage: nexon,
        //     client: "",
        //     brand: "",
        //     slider1Image: [
        //       {
        //         img: nexon,
        //       },
        //       {
        //         img: nexon1,
        //       },
        //       {
        //         img: nexon2,
        //       },
        //       {
        //         img: nexon,
        //       },
        //     ],
        //     paraHeading: "Demo Car Configurator",
        //     heading1:
        //       "Demo Car Configurator: Rapid Deployment of Interactive 3D Visualization",
        //     heading2: "Project Overview",
        //     para1:
        //       "We partnered to create a rapid-turnaround car configurator, demonstrating our prowess in interactive, high-quality 3D visualizations. This project highlights our ability to deliver premium experiences swiftly, reinforcing our position as the top choice for challenging visualization tasks.",
        //     para2:
        //       "Our team tackled a high-stakes challenge: creating a premium car configurator in just one month. Despite familiar territory, the tight deadline and demand for top-tier quality with novel interaction methods pushed us to innovate, showcasing Reality Scale's expertise in 3D visualization.",
        //     impact: "Impact",
        //     impactberief:
        //       "Reality Scale showcased expertise in delivering premium, interactive 3D experiences under pressure. We exceeded expectations in quality and speed, merging technical prowess with creative solutions. This success reinforces our position as the leading partner for swift, high-caliber 3D visualization.",

        //     featureheading: "Key Feature",
        //     technical: "Project Highlights:",
        //     technicalsubheading: "",
        //     featurelist: [
        //       {
        //         list: "Interactive 3D car model with high-fidelity visuals",
        //       },
        //       {
        //         list: "Rapid prototyping of multiple interaction models",
        //       },
        //       {
        //         list: "Flexible variant swapping mechanism",
        //       },
        //       {
        //         list: "Modular, intuitive UI optimized for performance and visual quality",
        //       },
        //     ],

        //     technicallist: [
        //       {
        //         head: "Rapid 3D Modeling and Optimization",
        //         list: "wiftly photographed, modeled, and optimized car data for real-time Unreal Engine rendering.",
        //       },
        //       {
        //         head: "Intuitive User Experience",
        //         list: "Implemented a unique hotspot system for intuitive part interaction.",
        //       },
        //       {
        //         head: "Flexible Customization",
        //         list: "Developed a dynamic variant swapping system for mixing and matching car parts.",
        //       },
        //       {
        //         head: "Agile UI Implementation",
        //         list: "Leveraged modular systems to create and integrate a user-friendly interface.",
        //       },
        //       {
        //         head: "Visual Polish and Refinement",
        //         list: "Focused on polishing visuals and adding mechanisms to elevate overall quality.",
        //       },
        //     ],
        //     slider2: [
        //       {
        //         img: mahindra,
        //       },
        //       {
        //         img: mahindra1,
        //       },
        //       {
        //         img: mahindra2,
        //       },
        //       {
        //         img: mahindra3,
        //       },
        //     ],
        //   },
        // },
        {
          id: 1,
          img: nexon,
          thumbnail: nexon,
          title: "Automotive",
          description: "Description for Automotive Image 2",
          content: "Mahindra Project",
          heading: "Mahindra Project",
          video:
            "https://www.youtube.com/embed/deu0PnGsZgg?si=cWgTyLAT4gT3q6Tt",
          subheading:
            "Showroom-based configurator displaying cars on a 60-inch screen. Unique feature allows sales consultants to control the display using their mobile devices, enhancing the customer experience.",

          productDetails: {
            bannerImage: nexon,
            client: "Disney",
            brand: "",
            slider1Image: [
              {
                img: nexon,
              },
              {
                img: nexon1,
              },
              {
                img: nexon2,
              },
              {
                img: nexon,
              },
            ],
            paraHeading: "Tata Nexon",
            heading1: "Pyjama Ride: An Exciting VR Adventure for Kids",
            heading2: "Project Overview",
            para1:
              "One of our most thrilling projects to date, Pyjama Ride is an immersive Virtual Reality experience designed for a popular children's show. This project showcases our expertise in creating engaging, child-friendly VR content while pushing the boundaries of real-time gameplay and dynamic environments.",
            para2:
              "Pyjama Ride puts young players in control of a sledge racing down a sprawling mountain landscape. This multi-level adventure mimics the excitement of a roller coaster ride, taking children through various thrilling scenarios and unexpected events.",
            impact: "Impact",
            impactberief:
              "Pyjama Ride demonstrates our ability to create engaging, age-appropriate content that sparks imagination in young users. This project stands as a testament to our commitment to innovation and expertise in crafting unforgettable virtual experiences.",

            featureheading: "Key Feature",
            technical: "Technical Achievements",
            technicalsubheading:
              "Our team overcame several challenges to bring this project to life:",
            featurelist: [
              {
                list: "Intuitive controls for young players",
              },
              {
                list: "Multiple levels with varied terrain and challenges",
              },
              {
                list: "Dynamic events (e.g., collapsing rocks, flowing lava)",
              },
              {
                list: "Real-time gameplay recording capabilities",
              },
            ],

            technicallist: [
              {
                head: "Dynamic Track System",
                list: "We developed a system allowing for custom tracks with precise control over sledge speed and track shape.",
              },
              {
                head: "Real-time Recording",
                list: "We implemented a system for capturing the VR gameplay in real-time.",
              },
              {
                head: "Expansive, Seamless World",
                list: "Our developers crafted a vast, multi-level map providing a continuous sliding experience.",
              },
              {
                head: "Dynamic Event Triggering",
                list: "We created a flexible system for triggering real-time events during gameplay.",
              },
              {
                head: "VR Optimization",
                list: "The entire experience was optimized for smooth VR performance.",
              },
            ],
            slider2: [
              {
                img: mahindra,
              },
              {
                img: mahindra1,
              },
              {
                img: mahindra2,
              },
              {
                img: mahindra3,
              },
            ],
          },
        },
      ],
    },
    {
      label: "Architecture",
      slides: [
        {
          id: 0,
          img: screencraft,
          thumbnail: screencraft,
          title: "Automotive",
          description: "Description for Automotive Image 1",
          video:
            "https://www.youtube.com/embed/RH2A96twDKs?si=uK_ma0BrWrjsnB03",
          content: "Screen Craft",
          heading: "Screen Craft",
          subheading:
            "Scene Craft combines real-time WebGL with cloud rendering, delivering high-resolution fabric visualisation up to 16K while reducing costs for textile businesses.",
          productDetails: {
            bannerImage: screencraft,
            client: "",
            brand: "",
            slider1Image: [
              {
                img: screencraft,
              },
              {
                img: screencraft,
              },
              {
                img: screencraft,
              },
              {
                img: screencraft,
              },
            ],
            paraHeading: "Screen Craft",
            heading1: "Scene Craft: Advanced Fabric Rendering Platform",
            heading2: "Project Overview",
            para1:
              "Scene Craft represents one of Reality Scale's most technologically advanced project. Born from the need for high-quality, cost-effective fabric rendering, this platform pushes the boundaries of what's possible in digital textile visualisation. By leveraging our proprietary hybrid rendering technology, Scene Craft offers an unparalleled combination of real-time interactivity and photorealistic output sets a new industry standard.",
            para2:
              "Scene Craft evolved from our initial WebGL solution, addressing limitations in render quality and high server costs of our real-time interior solutions. Leveraging our research in hybrid rendering, we developed Scene Craft as a SaaS platform for fabric and textile makers. This innovative approach combines real-time WebGL rendering for interactive adjustments with cloud-based high-quality rendering for final outputs, creating a more advanced and scalable product.",
            impact: "Impact",
            impactberief:
              "Scene Craft has revolutionised fabric rendering by combining real-time interactivity with photorealistic output at unprecedented resolutions. Its scalable, cost-effective approach makes high-quality visualisation accessible to businesses of all sizes, accelerating product development and enhancing marketing capabilities. This project delivers superior rendering solutions and showcases Reality Scale's ability to innovate and overcome complex technical challenges, reinforcing our position as industry leaders in digital visualisation.",

            featureheading: "Key Feature",
            technical: "Technical Achievements",
            technicalsubheading: "",
            featurelist: [
              {
                list: "Combines WebGL for real-time manipulation with cloud-based high-quality rendering, offering unmatched flexibility.",
              },
              {
                list: "Produces renders up to 16K resolution, matching V-Ray quality with real-time adjustability.",
              },
              {
                list: "Offers various material types and adjustable properties for extensive customization.",
              },
              {
                list: "Accommodates multiple users simultaneously at reduced costs compared to traditional solutions.",
              },
              {
                list: "Allows scene uploads, user access management, and platform oversight.",
              },
              {
                list: "Enables quick scene adjustments with immediate visual feedback.",
              },
              {
                list: "Provides intuitive controls for texture uploads, property adjustments, and render initiation.",
              },
            ],

            technicallist: [
              {
                head: "Hybrid Rendering Pipeline:",
                list: "Integrated real-time WebGL and high-quality cloud-based rendering, solving synchronisation and data management challenges.",
              },
              {
                head: "Scalable Cloud Architecture: ",
                list: " Built infrastructure handling multiple rendering requests cost-effectively.",
              },
              {
                head: "Real-Time to High-Quality:",
                list: "Developed system translating WebGL adjustments into high-quality render Parameters, ensuring preview-output consistency.",
              },
              {
                head: "Advanced Material System:",
                list: "Created a flexible system that accurately represents complex fabrics in real-time and high-quality renders.",
              },
              {
                head: "High-Resolution Rendering:",
                list: " Engineered rendering up to 16K, balancing quality and rendering time.",
              },
              {
                head: "Scene Management System:",
                list: "Implemented robust backend for efficient scene and user management.",
              },
              {
                head: "Cross-Platform Compatibility:",
                list: " Ensured functionality across devices and browsers, overcoming WebGL and cloud integration challenges.",
              },
            ],
            slider2: [
              {
                img: mahindra,
              },
              {
                img: mahindra1,
              },
              {
                img: mahindra2,
              },
              {
                img: mahindra3,
              },
            ],
          },
        },
      ],
    },
  ],
};
export default NewData;
