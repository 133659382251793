import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import "../../style/transform.css";
import automotiveimage from "../../assests/images/aboutus.png";
const AIIntegration = () => {
  return (
    <div className="">
      <Box className="mil-content">
        <div id="swupMain" className="mil-main-transition ">
          <div className="bg-black mil-dark-bg page_alignment ">
            <div className=" mil-inner-banner">
              <div className="mi-invert-fix">
                <div className="mil-banner-content mil-up">
                  <div className="mil-animation-frame">
                    <div
                      className="mil-animation mil-position-1 mil-scale"
                      data-value-1="7"
                      data-value-2="1.6"
                      style={{
                        transform: "translate3d(0px, 0px, 0px) scale(2, 2)",
                      }}
                    >
                      <div className="mil-dodecahedron">
                        {[...Array(12)].map((_, index) => (
                          <div key={index} className="mil-pentagon">
                            {[...Array(5)].map((_, index) => (
                              <div key={index}></div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="container">
                    <div className="">
                      <ul
                        className="mil-breadcrumbs mil-light mil-mb-60"
                        style={{ padding: "0" }}
                      >
                        <li>
                          <a href="/">Homepage</a>
                        </li>
                        <li>
                          <a href="#">Automotive Details</a>
                        </li>
                      </ul>
                      <h1
                        className="mil-muted mil-mb-60"
                        style={{ color: "#ffd94a" }}
                      >
                        Harness{" "}
                        <span className="mil-thins" style={{ color: "white" }}>
                          the Power
                        </span>
                        <br /> of{" "}
                        <span className="mil-thins" style={{ color: "white" }}>
                          Intelligent Solutions
                        </span>
                      </h1>
                      <a
                        href="#"
                        className="mil-link mil-accent mil-arrow-place mil-down-arrow"
                      ></a>
                    </div>
                    <div>
                      {/* automotive defination start */}
                      <Box>
                        <Box
                          sx={{
                            marginBottom: 5,
                          }}
                        >
                          <Typography variant="p" color={"#fff"}>
                            At Reality Scale, we leverage cutting-edge AI/ML to
                            transform businesses across industries. Our AI/ML
                            services enhance user experiences, optimize
                            operations, and drive growth through innovative,
                            tailored solutions.
                          </Typography>
                        </Box>
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={6} md={8} lg={6}>
                            <img
                              src={automotiveimage}
                              width={"100%"}
                              height={"100%"}
                            />
                          </Grid>
                          <Grid item xs={6} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                                // justifyContent: "start",
                              }}
                            >
                              <Typography
                                variant="h2"
                                fontWeight={500}
                                fontSize={35}
                              >
                                RAG Framework
                              </Typography>
                              <Box
                                sx={{
                                  mt: 2,
                                }}
                              >
                                <Typography color={"#fff"} variant="p">
                                  Our Retrieval-Augmented Generation (RAG)
                                  framework creates use case-specific AI
                                  solutions that analyse vast document
                                  repositories, delivering targeted information
                                  to support informed decision-making.
                                </Typography>
                              </Box>{" "}
                              <Box
                                sx={{
                                  mt: 2,
                                }}
                              >
                                <Typography color={"#fff"} variant="p">
                                  Integrating real-time, context-aware data from
                                  diverse sources enhances domain-specific
                                  knowledge and efficiency. This system empowers
                                  users with comprehensive insights for
                                  specialised business applications.
                                </Typography>
                              </Box>
                              <Box>
                                <ul
                                  style={{
                                    marginTop: "10px",
                                    color: "#fff",
                                  }}
                                >
                                  <li>
                                    Multi-modal data processing (text, images,
                                    video, audio)
                                  </li>
                                  <li>
                                    Real-time access to up-to-date information
                                  </li>
                                  <li>
                                    Robust security with Attribute-Based Access
                                    Control (ABAC)
                                  </li>
                                  <li>
                                    Seamless integration with external agents
                                    and APIs
                                  </li>
                                  <li>
                                    Scalable and flexible to meet diverse
                                    business needs
                                  </li>
                                  <li>
                                    Available as SaaS or self-hosted solution
                                  </li>
                                  <li>
                                    Kaman technology integration for automatic
                                    data source updates
                                  </li>
                                </ul>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      {/* automotive defination ends */}{" "}
                      {/* automotive NeoReal Platform start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={6} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography
                                variant="h2"
                                fontWeight={500}
                                fontSize={35}
                              >
                                Building your AI SaaS
                              </Typography>
                              <Box
                                sx={{
                                  mt: 1,
                                }}
                              >
                                <Typography color={"#fff"} variant="p">
                                  Transform your business idea into a scalable
                                  AI-powered Software-as-a-Service solution.
                                </Typography>
                              </Box>{" "}
                            </Box>
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  Prompt Engineering, Fine-tuning model or RAG
                                  system
                                </li>
                                <li className="mt-2">
                                  Scalable cloud architecture design
                                </li>
                                <li className="mt-2">
                                  Robust security and compliance measures
                                </li>
                                <li className="mt-2">
                                  Continuous monitoring and improvement systems
                                </li>
                                <li className="mt-2">
                                  Integration with existing business systems and
                                  workflows
                                </li>
                              </ul>
                            </Box>{" "}
                          </Grid>
                          <Grid item xs={6} md={8} lg={6}>
                            <img src={automotiveimage} width={"100%"} />
                          </Grid>
                        </Grid>
                      </Box>
                      {/* automotive NeoReal Platform ends */}{" "}
                      {/* automotive Immersive technology start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={6} md={8} lg={6}>
                            <img src={automotiveimage} width={"100%"} />
                          </Grid>
                          <Grid item xs={6} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography
                                variant="h2"
                                fontWeight={500}
                                fontSize={35}
                              >
                                AI Sales Assistant
                              </Typography>
                              <Box
                                sx={{
                                  mt: 1,
                                }}
                              >
                                <Typography color={"#fff"} variant="p">
                                  Revolutionize your sales process with our
                                  intelligent assistant, seamlessly integrated
                                  with digital twin technologies.
                                </Typography>
                              </Box>{" "}
                            </Box>
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  Real-time, personalized product information
                                  and recommendations
                                </li>
                                <li className="mt-2">
                                  Guided product exploration and customization
                                </li>{" "}
                                <li className="mt-2">
                                  In-depth analysis of customer interactions
                                </li>{" "}
                                <li className="mt-2">
                                  Enhanced engagement in virtual showrooms and
                                  demonstrations
                                </li>
                              </ul>
                            </Box>{" "}
                          </Grid>
                        </Grid>
                      </Box>
                      {/* automotive Immersive Technology ends */}
                      {/* automotive Immersive technology start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={6} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography
                                variant="h2"
                                fontWeight={500}
                                fontSize={35}
                              >
                                Computer Vision
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography
                                color={"#fff"}
                                variant="h4"
                                fontSize={18}
                              >
                                Document Verification
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  Automated identity and document authentication
                                </li>
                                <li className="mt-2">
                                  Enhanced security for digital transactions
                                </li>{" "}
                              </ul>
                            </Box>{" "}
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography
                                color={"#fff"}
                                variant="h4"
                                fontSize={18}
                              >
                                Real-world Event Detection
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  Proactive monitoring and response in physical
                                  environments
                                </li>
                              </ul>
                            </Box>{" "}
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography
                                color={"#fff"}
                                variant="h4"
                                fontSize={18}
                              >
                                Environmental Mapping{" "}
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>Detailed 3D mapping</li>
                              </ul>
                            </Box>
                          </Grid>
                          <Grid item xs={6} md={8} lg={6}>
                            <img src={automotiveimage} width={"100%"} />
                          </Grid>
                        </Grid>
                      </Box>
                      {/* automotive Immersive Technology ends */}{" "}
                      {/* automotive Immersive technology start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={6} md={8} lg={6}>
                            <img src={automotiveimage} width={"100%"} />
                          </Grid>
                          <Grid item xs={6} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography
                                variant="h2"
                                fontWeight={500}
                                fontSize={35}
                              >
                                Intelligent Analytics and Insights
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography color={"#fff"} variant="p">
                                Gain deep insights from user interactions and
                                system data.
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  Behavioral analysis in virtual environments
                                </li>
                                <li className="mt-2">
                                  Predictive analytics for sales and trends
                                </li>{" "}
                                <li className="mt-2">
                                  Pattern recognition for customer preferences
                                </li>{" "}
                                <li className="mt-2">
                                  Data-driven decision support
                                </li>{" "}
                              </ul>
                            </Box>{" "}
                          </Grid>
                        </Grid>
                      </Box>
                      {/* automotive Immersive Technology ends */}{" "}
                      {/* automotive Immersive technology start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={6} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography
                                variant="h2"
                                fontWeight={500}
                                fontSize={35}
                              >
                                Generative AI Content
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography color={"#fff"} variant="p">
                                Create and optimize content across various
                                applications.
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  Automated 3D model and texture generation
                                </li>
                                <li className="mt-2">
                                  Realistic virtual environment creation
                                </li>{" "}
                                <li className="mt-2">
                                  Personalized content based on user preferences
                                </li>{" "}
                                <li className="mt-2">
                                  Streamlined content creation workflows
                                </li>{" "}
                              </ul>
                            </Box>{" "}
                          </Grid>
                          <Grid item xs={6} md={8} lg={6}>
                            <img src={automotiveimage} width={"100%"} />
                          </Grid>
                        </Grid>
                      </Box>
                      {/* automotive Immersive Technology ends */}
                      {/* automotive advantage start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={6} md={8} lg={6}>
                            <img src={automotiveimage} width={"100%"} />
                          </Grid>
                          <Grid item xs={6} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography
                                variant="h2"
                                fontWeight={500}
                                fontSize={35}
                              >
                                Additional AI/ML Services:
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <ol
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  Integrating OpenAI's GPT-4/ChatGPT into your
                                  business flow
                                </li>
                                <li className="mt-2">
                                  Designing a prompt engineering solution to
                                  decrease some of the workload in your business
                                </li>
                                <li className="mt-2">
                                  Finetune an LLM model specific to your niche
                                </li>
                                <li className="mt-2">
                                  Voice recognition and synthesis
                                </li>
                                <li className="mt-2">
                                  AI/ML-assisted virtual space layout
                                  optimisation
                                </li>
                                <li className="mt-2">
                                  Gesture recognition for intuitive VR
                                  interactions
                                </li>
                              </ol>
                            </Box>{" "}
                            <Box
                              sx={{
                                mt: 1,
                              }}
                            >
                              <Typography color={"#fff"} variant="p">
                                Partner with Reality Scale to transform your
                                automotive business. Contact us today to
                                discover how we can elevate your brand and drive
                                success in the evolving automotive landscape.
                              </Typography>
                            </Box>{" "}
                          </Grid>
                        </Grid>
                      </Box>
                      {/* automotive advantage ends */}
                      <Box
                        sx={{
                          mt: 3,
                        }}
                      >
                        <Typography variant="p" color={"#fff"}>
                          Our team of AI/ML experts is ready to develop tailored
                          solutions that address your specific industry
                          challenges and use cases. Partner with Reality Scale
                          to unlock your business's full potential with AI/ML.
                        </Typography>
                        <Box
                          sx={{
                            marginTop: "10px",
                          }}
                        >
                          <Typography variant="p" color={"#fff"}>
                            Contact us today to explore how our AI/ML services
                            can drive innovation and growth for your
                            organisation.
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default AIIntegration;
