import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import "../../style/transform.css";

import automotiveimage from "../../assests/images/aboutus.png";
const RealEstate = () => {
  return (
    <div className="">
      <Box className="mil-content">
        <div id="swupMain" className="mil-main-transition ">
          <div className="bg-black mil-dark-bg page_alignment ">
            <div className=" mil-inner-banner">
              <div className="mi-invert-fix">
                <div className="mil-banner-content mil-up">
                  <div className="mil-animation-frame">
                    <div
                      className="mil-animation mil-position-1 mil-scale"
                      data-value-1="7"
                      data-value-2="1.6"
                      style={{
                        transform: "translate3d(0px, 0px, 0px) scale(2, 2)",
                      }}
                    >
                      <div className="mil-dodecahedron">
                        {[...Array(12)].map((_, index) => (
                          <div key={index} className="mil-pentagon">
                            {[...Array(5)].map((_, index) => (
                              <div key={index}></div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="container">
                    <div className="">
                      <ul
                        className="mil-breadcrumbs mil-light mil-mb-60"
                        style={{ padding: "0" }}
                      >
                        <li>
                          <a href="/">Homepage</a>
                        </li>
                        <li>
                          <a href="#">Automotive Details</a>
                        </li>
                      </ul>
                      <h1
                        className="mil-muted mil-mb-60"
                        style={{ color: "#ffd94a" }}
                      >
                        REAL-ESTATE{" "}
                        <span className="mil-thins" style={{ color: "white" }}>
                          SOLUTIONS{" "}
                        </span>
                        {/* <br /> is finally{" "}
                      <span className="mil-thins" style={{ color: "white" }}>
                        launched
                      </span> */}
                      </h1>
                      <a
                        href="#"
                        className="mil-link mil-accent mil-arrow-place mil-down-arrow"
                      ></a>
                    </div>
                    <div>
                      {/* vision section start */}
                      <Box>
                        <Typography variant="p" color={"#fff"}>
                          At Reality Scale, we understand that real estate is
                          more than just a market – it's the cornerstone of
                          people's lives. Our mission is to bridge the trust gap
                          between the real estate industry and its clients,
                          providing tools to builders and interior designers to
                          sell and display better. We aim to create experiences
                          that allow potential buyers to make confident,
                          informed decisions about their future homes.
                        </Typography>
                        <Box
                          sx={{
                            mt: 5,
                          }}
                        >
                          <Grid
                            container
                            spacing={2}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Grid item xs={6} md={8} lg={6}>
                              <img src={automotiveimage} width={"100%"} />
                            </Grid>
                            <Grid item xs={6} md={4} lg={6}>
                              <Box
                                sx={{
                                  display: "grid",
                                  alignItems: "start",
                                  // justifyContent: "start",
                                }}
                              >
                                <Typography
                                  variant="h2"
                                  fontWeight={500}
                                  fontSize={35}
                                >
                                  Our Vision
                                </Typography>
                                <Box
                                  sx={{
                                    mt: 3,
                                  }}
                                >
                                  <Typography color={"#fff"} variant="p">
                                    Reality Scale isn't just about pretty
                                    pictures or fancy tech – it's about
                                    fundamentally changing how properties are
                                    experienced, marketed, and sold. Potential
                                    buyers can make informed decisions from
                                    anywhere, and where real estate
                                    professionals can work more efficiently and
                                    effectively than ever before.
                                  </Typography>
                                </Box>{" "}
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                      {/* vision section ends */}{" "}
                      {/* real state solution start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={6} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography
                                variant="h2"
                                fontWeight={500}
                                fontSize={35}
                              >
                                Real-Estate solution
                              </Typography>
                              <Box
                                sx={{
                                  mt: 1,
                                }}
                              >
                                <Typography color={"#fff"} variant="p">
                                  Reality Scale provides an end-to-end solution
                                  for builders, revolutionising the real estate
                                  process from product creation to sale.
                                </Typography>
                              </Box>{" "}
                            </Box>
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">
                                1. Property Development and Planning
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>Visualization: </b> 3D property previews
                                  for informed design choices
                                </li>
                                <li className="mt-2">
                                  <b>Engagement and Collaboration:</b> Remote
                                  collaboration platform
                                </li>
                                <li className="mt-2">
                                  <b>Cost Reduction: </b> Minimize physical
                                  prototyping and staging
                                </li>
                              </ul>
                            </Box>{" "}
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">
                                2. Marketing Material Preparation
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>Hyper-Realistic Property Showcases: </b>{" "}
                                  Photo-quality images and videos
                                </li>
                                <li className="mt-2">
                                  <b>Effortless Content Creation:</b> Quick
                                  brochure and video creation
                                </li>
                                <li className="mt-2">
                                  <b>Streamlined Marketing Workflow: </b>{" "}
                                  Optimize marketing efforts
                                </li>
                              </ul>
                            </Box>{" "}
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">
                                3. Lead Generation
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>AI-Powered Virtual Tours: </b> Interactive
                                  VR property experiences
                                </li>
                                <li className="mt-2">
                                  <b>Broader Reach:</b> Global property showcase
                                </li>
                                <li className="mt-2">
                                  <b>24/7 AI Sales Assistant: </b> Instant
                                  information and guided tours
                                </li>
                              </ul>
                            </Box>{" "}
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">
                                4. Initial Buyer Engagement
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>Remote Sales Tools: </b> Conduct tours and
                                  close deals remotely
                                </li>
                                <li className="mt-2">
                                  <b>Personalized Experiences: </b> Customizable
                                  property exploration
                                </li>
                                <li className="mt-2">
                                  <b>Early Buyer Engagement: </b> AI-powered
                                  engagement tools
                                </li>
                              </ul>
                            </Box>{" "}
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">
                                5. In-Office Experience & Follow-Up
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>VR & Physical Displays: </b> Immersive
                                  in-office presentations
                                </li>
                                <li className="mt-2">
                                  <b>Tailored Marketing:</b> Personalized
                                  follow-up materials
                                </li>
                                <li className="mt-2">
                                  <b>Data-Driven Insights: </b> Analytics for
                                  targeted presentations
                                </li>
                              </ul>
                            </Box>{" "}
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">
                                6. Customization and Upselling
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>Buyer Customization: </b> Visualize
                                  upgrades and add-ons
                                </li>
                                <li className="mt-2">
                                  <b>Effective Upselling:</b> Seamless feature
                                  integration during tours
                                </li>
                              </ul>
                            </Box>{" "}
                          </Grid>
                          <Grid item xs={6} md={8} lg={6}>
                            <img src={automotiveimage} width={"100%"} />
                          </Grid>
                        </Grid>
                      </Box>
                      {/* real state solution ends */}{" "}
                      {/* Interior design start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={6} md={8} lg={6}>
                            <img src={automotiveimage} width={"100%"} />
                          </Grid>
                          <Grid item xs={6} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography
                                variant="h2"
                                fontWeight={500}
                                fontSize={35}
                              >
                                Interior Design Solutions
                              </Typography>
                              <Box
                                sx={{
                                  mt: 1,
                                }}
                              >
                                <Typography color={"#fff"} variant="p">
                                  Reality Scale's platform extends beyond real
                                  estate to revolutionize interior design
                                  processes:
                                </Typography>
                              </Box>{" "}
                            </Box>
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">
                                1. Customized Design Tools
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>Tailored Solutions: </b>
                                  Tools designed for specific designer needs and
                                  budgets
                                </li>
                                <li className="mt-2">
                                  <b>Workflow Optimization:</b> Custom solutions
                                  to streamline design processes
                                </li>
                              </ul>
                            </Box>{" "}
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">
                                2. High-Quality Visualization
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>Photorealistic Renders: </b> Up to 16K
                                  resolution renders
                                </li>
                                <li className="mt-2">
                                  <b>Immersive Videos:</b> High-quality virtual
                                  walkthroughs
                                </li>
                              </ul>
                            </Box>{" "}
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">
                                3. Virtual Design Consultation
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>Remote Collaboration: </b> Real-time
                                  virtual design discussions
                                </li>
                                <li className="mt-2">
                                  <b>Interactive Mood Boards: </b> Instant
                                  feedback on digital mood boards
                                </li>
                              </ul>
                            </Box>{" "}
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">
                                4. 3D Room Visualization
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>Realistic Renderings: </b> Immersive 3D
                                  environments from 2D plans
                                </li>
                                <li className="mt-2">
                                  <b>Lighting Simulation:</b> Showcase lighting
                                  effects at different times
                                </li>
                              </ul>
                            </Box>{" "}
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">
                                5. Furniture and Decor Placement
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>Virtual Staging: </b> 3D furniture and
                                  decor placement
                                </li>
                                <li className="mt-2">
                                  <b>Product Integration: </b> Seamless
                                  integration of retailer catalogs
                                </li>
                              </ul>
                            </Box>{" "}
                          </Grid>
                        </Grid>
                      </Box>
                      {/* Interior design ends */}
                      {/* Decor start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={6} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography
                                variant="h2"
                                fontWeight={500}
                                fontSize={35}
                              >
                                Decor Solutions
                              </Typography>
                              <Box
                                sx={{
                                  mt: 1,
                                }}
                              >
                                <Typography color={"#fff"} variant="p">
                                  Empower your interior design and decor
                                  business with Reality Scale's visualization
                                  platform:
                                </Typography>
                              </Box>{" "}
                            </Box>
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">
                                1. Product Showcasing
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>Dynamic Catalogs: </b>
                                  Interactive, updatable digital product
                                  catalogues
                                </li>
                                <li className="mt-2">
                                  <b>Contextual Visualization: </b> Show
                                  products in various room settings
                                </li>
                              </ul>
                            </Box>{" "}
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">
                                2. Customer Exploration
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>Interactive Product Views: </b> 360-degree
                                  product rotations and zooming
                                </li>
                                <li className="mt-2">
                                  <b>Virtual Try-On: </b> Place products in
                                  customer-uploaded room images
                                </li>
                              </ul>
                            </Box>{" "}
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">
                                3. Tailored Solutions
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>Budget-Based Recommendations: </b> Suggest
                                  products within customer price ranges
                                </li>
                                <li className="mt-2">
                                  <b>Use-Case Optimization: </b> Tailor
                                  solutions for interior design or retail
                                  selling
                                </li>
                              </ul>
                            </Box>{" "}
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">
                                4. Flexible Platform Options
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>Cloud-Based Access: </b> Web-based platform
                                  for widespread accessibility
                                </li>
                                <li className="mt-2">
                                  <b>AR Integration: </b> Augmented reality
                                  features for mobile devices
                                </li>{" "}
                                <li className="mt-2">
                                  <b>VR Experiences: </b> Immersive virtual
                                  reality showrooms and product demos
                                </li>
                              </ul>
                            </Box>{" "}
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">
                                5. Sales Enhancement
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>Real-Time Inventory: </b> Live stock
                                  updates across all platforms
                                </li>
                                <li className="mt-2">
                                  <b>Analytics Dashboard: </b> Track user
                                  interactions and sales performance
                                </li>
                              </ul>
                            </Box>{" "}
                          </Grid>
                          <Grid item xs={6} md={8} lg={6}>
                            <img src={automotiveimage} width={"100%"} />
                          </Grid>
                        </Grid>
                      </Box>
                      {/* decor ends */}
                      {/* automotive advantage start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={6} md={8} lg={6}>
                            <img src={automotiveimage} width={"100%"} />
                          </Grid>
                          <Grid item xs={6} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography
                                variant="h2"
                                fontWeight={500}
                                fontSize={35}
                              >
                                Key Benefits of Reality Scale:
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <ol
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>Increased Buyer Interest: </b> Stunning
                                  visuals capture attention and generate more
                                  inquiries across real estate, interior design,
                                  and decor.
                                </li>
                                <li className="mt-2">
                                  <b>Reduced Physical Visits: </b> High-quality
                                  showcases allow buyers and design clients to
                                  pre-screen properties and options, saving time
                                  for all parties.
                                </li>
                                <li className="mt-2">
                                  <b>Global Reach: </b> Showcase properties and
                                  design concepts to potential buyers and
                                  clients worldwide, expanding your market.
                                </li>
                                <li className="mt-2">
                                  <b>Faster Decision-Making: </b> Enable quicker
                                  decision-making by providing comprehensive,
                                  immersive experiences remotely.
                                </li>
                                <li className="mt-2">
                                  <b>Improved Customer Service:</b> Provide
                                  instant, accurate responses to questions about
                                  properties, designs, and decor options,
                                  enhancing satisfaction.
                                </li>
                                <li className="mt-2">
                                  <b>Time and Cost Savings:</b> Drastically
                                  reduce the time and resources spent on
                                  creating marketing materials, conducting
                                  physical showings, and producing physical
                                  design mock-ups.
                                </li>{" "}
                                <li className="mt-2">
                                  <b>Enhanced Brand Presence:</b> Maintain a
                                  professional, cohesive brand across all
                                  properties, design projects, and campaigns.
                                </li>{" "}
                                <li className="mt-2">
                                  <b>Data-Driven Decision Making:</b> Use
                                  insights to guide your business strategies in
                                  real estate, interior design, and decor
                                  selection.
                                </li>
                              </ol>
                            </Box>{" "}
                          </Grid>
                        </Grid>
                        <Box
                          sx={{
                            mt: 3,
                          }}
                        >
                          <Typography color={"#fff"} variant="p">
                            Transform your real estate, interior design, and
                            decor business with Reality Scale. Our platform
                            showcases properties in detail, streamlines
                            workflows, and boosts client engagement. Elevate
                            your sales and create stunning home experiences with
                            our powerful visualization technology.
                          </Typography>
                        </Box>{" "}
                      </Box>
                      {/* automotive advantage ends */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default RealEstate;
