import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Footer from "../components/footer";
import Mouse from "../components/mouse";
import Navbar from "../components/navbar";
import Progressbar from "../components/progressbar";
import Blog from "../views/blog";
import BlogDetails from "../views/blogdetails";
import Contactus from "../views/contact";
import DEMOREALITY from "../views/demoreality";
import FeatureDetails from "../views/featuredetails";
import FirstDisneyProject from "../views/featurelistDisneyProject";
import Home from "../views/home";
import Commingsoon from "../views/page";
import Privacy from "../views/privacy";
import Portfolio from "../views/product";
import Portfoliodetails from "../views/productdetails";
import Member from "../views/team_member";
import TeamDetails from "../views/TeamDetails";
import Terms from "../views/terms";
import AIIntegration from "../views/transform/aiintegration";
import Automotive from "../views/transform/automative";
import Framework from "../views/transform/framework";
import Frontier from "../views/transform/frontier";
import Immersion from "../views/transform/immersion";
import RealEstate from "../views/transform/realEstate";
// import TransformDetails from "../views/transformdetailspage";

const Router = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname !== "/demoreality" && <Navbar />}
      <Mouse />
      <Progressbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/team" element={<Member />} />
        <Route path="/contact" element={<Contactus />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/Portfoliodetails" element={<Portfoliodetails />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/demoreality" element={<DEMOREALITY />} />
        <Route path="/features-details/:heading" element={<FeatureDetails />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blogdetails" element={<BlogDetails />} />
        <Route path="/Commingsoon" element={<Commingsoon />} />
        <Route path="/TeamDetails" element={<TeamDetails />} />
        <Route path="/FirstDisneyProject" element={<FirstDisneyProject />} />
        <Route path="/Transform/AI-Integration" element={<AIIntegration />} />
        <Route path="/Transform/Automotive" element={<Automotive />} />
        <Route
          path="/Transform/RealityScaleFramework"
          element={<Framework />}
        />
        <Route path="/Transform/Web3Frontier" element={<Frontier />} />
        <Route path="/Transform/XRImmersion" element={<Immersion />} />
        <Route path="/Transform/RealEstateSolution" element={<RealEstate />} />
      </Routes>

      {location.pathname !== "/demoreality" && (
        <>
          <Footer />
        </>
      )}
    </>
  );
};

export default Router;
