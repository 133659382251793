import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import "../../style/transform.css";
import immersionimg from "../../assests/transform/automotive/immersion.png";
import immersion14 from "../../assests/transform/automotive/immersion14.png";
import immersion04 from "../../assests/transform/automotive/immersive04.png";
import immersiveeducation from "../../assests/transform/automotive/immersiveeducation.png";
import immersiveretail from "../../assests/transform/automotive/immersiveretail.png";
import immersivetransformation from "../../assests/transform/automotive/immersivetransformation.png";
import immersivearchitecture from "../../assests/transform/automotive/06.png";
import automotiveimage from "../../assests/images/aboutus.png";
const Immersion = () => {
  return (
    <div className="">
      <Box className="mil-content">
        <div id="swupMain" className="mil-main-transition ">
          <div className="bg-black  page_alignment ">
            <div className=" mil-inner-banner">
              <div className="mi-invert-fix">
                <div className="mil-banner-content mil-up">
                  <div className="mil-animation-frame">
                    <div
                      className="mil-animation mil-position-1 mil-scale"
                      data-value-1="7"
                      data-value-2="1.6"
                      style={{
                        transform: "translate3d(0px, 0px, 0px) scale(2, 2)",
                      }}
                    >
                      <div className="mil-dodecahedron">
                        {[...Array(12)].map((_, index) => (
                          <div key={index} className="mil-pentagon">
                            {[...Array(5)].map((_, index) => (
                              <div key={index}></div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="container">
                      <ul
                        className="mil-breadcrumbs mil-light mil-mb-60"
                        style={{ padding: "0" }}
                      >
                        <li>
                          <a href="/">Homepage</a>
                        </li>
                        <li>
                          <a href="#">Automotive Details</a>
                        </li>
                      </ul>
                      <h1
                        className="mil-muted mil-mb-60"
                        style={{ color: "#ffd94a" }}
                      >
                        XR IMMERSIVE{" "}
                        <span className="mil-thins" style={{ color: "white" }}>
                          SOLUTIONS{" "}
                        </span>
                        {/* <br /> is finally{" "}
                      <span className="mil-thins" style={{ color: "white" }}>
                        launched
                      </span> */}
                      </h1>
                    </div>
                  </div>
                  <div className="container mt-5">
                    <div>
                      {/* vision section start */}
                      <Box>
                        <Typography variant="p" color={"#fff"}>
                          At Reality Scale, we're revolutionising how businesses
                          and individuals interact with digital content. Our XR
                          Immersive Solutions seamlessly blend the physical and
                          digital worlds, creating intuitive experiences that
                          expand human potential and solve complex business
                          challenges.
                        </Typography>
                        <Box
                          sx={{
                            mt: 5,
                          }}
                        >
                          <Grid
                            container
                            spacing={2}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Grid item xs={6} md={4} lg={6}>
                              <Box
                                sx={{
                                  display: "grid",
                                  alignItems: "start",
                                }}
                              >
                                <Typography
                                  variant="h2"
                                  fontWeight={500}
                                  fontSize={35}
                                >
                                  Understanding XR: The Future of Digital
                                  Interaction
                                </Typography>
                                <Box
                                  sx={{
                                    mt: 3,
                                  }}
                                >
                                  <Typography color={"#fff"} variant="p">
                                    Our XR solutions adapt to make the most
                                    impactful experience for each unique
                                    scenario, dissolving barriers between the
                                    digital and physical realms.
                                  </Typography>
                                </Box>{" "}
                                <Box
                                  sx={{
                                    mt: 3,
                                  }}
                                >
                                  <Typography variant="h6">
                                    Key Components:
                                  </Typography>
                                  <ul
                                    style={{
                                      color: "#fff",
                                      marginTop: "12px",
                                    }}
                                  >
                                    <li>
                                      <b>Augmented Reality (AR): </b> Enhances
                                      the real world with digital overlays
                                    </li>
                                    <li className="mt-2">
                                      <b>Virtual Reality (VR): </b> Immerses
                                      users in fully digital environments
                                    </li>
                                    <li className="mt-2">
                                      <b>Mixed Reality (MR): </b> Blends digital
                                      objects with the real world for
                                      simultaneous interaction
                                    </li>
                                  </ul>
                                </Box>{" "}
                                <Box
                                  sx={{
                                    mt: 3,
                                  }}
                                >
                                  <Typography variant="h6">
                                    The Reality Scale Advantage
                                  </Typography>
                                  <ul
                                    style={{
                                      color: "#fff",
                                      marginTop: "12px",
                                    }}
                                  >
                                    <li>
                                      <b>Adaptive Experiences: </b> Our XR
                                      solutions morph to meet specific needs
                                      across industries
                                    </li>
                                    <li className="mt-2">
                                      <b>Seamless Transitions: </b> Move fluidly
                                      between augmented, virtual, and mixed
                                      realities
                                    </li>
                                    <li className="mt-2">
                                      <b>Comprehensive Problem-Solving: </b>{" "}
                                      Tackle complex challenges with a full
                                      spectrum of reality-altering tools
                                    </li>
                                  </ul>
                                </Box>{" "}
                              </Box>
                            </Grid>
                            <Grid item xs={6} md={8} lg={6}>
                              <img src={immersionimg} width={"100%"} />
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                      {/* vision section ends */}{" "}
                      {/* real state solution start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Box
                          sx={{
                            marginBottom: "20px",
                          }}
                        >
                          <Typography
                            variant="h2"
                            fontWeight={500}
                            fontSize={35}
                          >
                            Industry-Specific Solutions
                          </Typography>{" "}
                        </Box>
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={6} md={8} lg={6}>
                            <img src={immersion14} width={"100%"} />
                          </Grid>
                          <Grid item xs={6} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography variant="h5" fontWeight={500}>
                                Healthcare
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">Challenges:</Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  High-stakes environments requiring
                                  ultra-precise simulations
                                </li>
                                <li className="mt-2">
                                  Strict regulatory compliance and data privacy
                                  concerns
                                </li>
                                <li className="mt-2">
                                  <b>Cost Reduction: </b> Minimize physical
                                  prototyping and staging
                                </li>
                              </ul>
                            </Box>{" "}
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">
                                Reality Scale Solutions:
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  Medical-grade accuracy in XR simulations,
                                  validated by leading professionals
                                </li>
                                <li className="mt-2">
                                  HIPAA-compliant data handling and storage
                                </li>
                                <li className="mt-2">
                                  Seamless integration with major EHR systems
                                  and medical imaging technologies
                                </li>
                              </ul>
                            </Box>{" "}
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">Benefits</Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  Enhanced surgical precision through immersive
                                  pre-operative planning
                                </li>
                                <li className="mt-2">
                                  Improved patient outcomes via realistic
                                  medical training simulations
                                </li>
                                <li className="mt-2">
                                  Reduced medical errors and increased patient
                                  safety
                                </li>
                              </ul>
                            </Box>{" "}
                          </Grid>
                        </Grid>
                      </Box>
                      {/* real state solution ends */}{" "}
                      {/* Interior design start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={6} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography
                                variant="h2"
                                fontWeight={500}
                                fontSize={35}
                              >
                                Manufacturing
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">Challenges:</Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  Complex assembly processes requiring real-time
                                  guidance
                                </li>
                                <li className="mt-2">
                                  High-cost consequences for production errors
                                </li>{" "}
                                <li className="mt-2">
                                  Diverse workforce with varying levels of
                                  technical expertise
                                </li>
                              </ul>
                            </Box>{" "}
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">
                                Reality Scale Solutions:
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  AR-guided assembly instructions overlaid
                                  directly onto work areas
                                </li>
                                <li className="mt-2">
                                  Real-time quality control integration with IoT
                                  sensors
                                </li>{" "}
                                <li className="mt-2">
                                  Intuitive, language-independent visual guides
                                </li>
                              </ul>
                            </Box>{" "}
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">Benefits:</Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  Significant reduction in assembly errors and
                                  associated costs
                                </li>
                                <li className="mt-2">
                                  Increased production efficiency and reduced
                                  training time
                                </li>{" "}
                                <li className="mt-2">
                                  Improved worker safety through AR-assisted
                                  hazard identification
                                </li>
                              </ul>
                            </Box>{" "}
                          </Grid>
                          <Grid item xs={6} md={8} lg={6}>
                            <img src={immersion04} width={"100%"} />
                          </Grid>
                        </Grid>
                      </Box>
                      {/* Interior design ends */}
                      {/* Decor start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={6} md={8} lg={6}>
                            <img src={immersiveeducation} width={"100%"} />
                          </Grid>
                          <Grid item xs={6} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography
                                variant="h2"
                                fontWeight={500}
                                fontSize={35}
                              >
                                Education
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">Challenges:</Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  Engaging students in an increasingly digital
                                  world
                                </li>
                                <li className="mt-2">
                                  Providing equitable access to quality
                                  educational experiences
                                </li>{" "}
                                <li className="mt-2">
                                  Visualizing complex or abstract concepts
                                </li>
                              </ul>
                            </Box>{" "}
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">
                                Reality Scale Solutions:
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  Immersive, interactive learning environments
                                </li>
                                <li className="mt-2">
                                  Cloud-based delivery ensuring accessibility
                                  from various devices
                                </li>{" "}
                                <li className="mt-2">
                                  3D visualization tools for abstract concepts
                                  in sciences and mathematics
                                </li>
                              </ul>
                            </Box>{" "}
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">Benefits:</Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  Increased student engagement and improved
                                  learning outcomes
                                </li>
                                <li className="mt-2">
                                  Democratized access to high-quality
                                  educational experiences
                                </li>{" "}
                                <li className="mt-2">
                                  Enhanced understanding of complex subjects
                                  through visual, hands-on learning
                                </li>
                              </ul>
                            </Box>{" "}
                          </Grid>
                        </Grid>
                      </Box>
                      {/* decor ends */}
                      {/* Decor start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={6} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography
                                variant="h2"
                                fontWeight={500}
                                fontSize={35}
                              >
                                Retail
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">Challenges:</Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  High return rates due to mismatched customer
                                  expectations
                                </li>
                                <li className="mt-2">
                                  Limited physical space for product showcases
                                </li>{" "}
                                <li className="mt-2">
                                  Creating engaging shopping experiences in
                                  e-commerce
                                </li>
                              </ul>
                            </Box>{" "}
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">
                                Reality Scale Solutions:
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  Hyper-realistic AR try-on experiences for
                                  clothing, makeup, and home decor
                                </li>
                                <li className="mt-2">
                                  Virtual showrooms allowing endless product
                                  displays in limited physical space
                                </li>{" "}
                                <li className="mt-2">
                                  Interactive 3D product visualizations for
                                  e-commerce platforms
                                </li>
                              </ul>
                            </Box>{" "}
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">Benefits:</Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  Reduced return rates and increased customer
                                  satisfaction
                                </li>
                                <li className="mt-2">
                                  Optimized use of retail space and expanded
                                  product offerings
                                </li>{" "}
                                <li className="mt-2">
                                  Enhanced online shopping experiences leading
                                  to higher conversion rates
                                </li>
                              </ul>
                            </Box>{" "}
                          </Grid>
                          <Grid item xs={6} md={8} lg={6}>
                            <img src={immersiveretail} width={"100%"} />
                          </Grid>
                        </Grid>
                      </Box>
                      {/* decor ends */} {/* Decor start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={6} md={8} lg={6}>
                            <img src={immersivearchitecture} width={"100%"} />
                          </Grid>
                          <Grid item xs={6} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography
                                variant="h2"
                                fontWeight={500}
                                fontSize={35}
                              >
                                Architecture & Real Estate
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">Challenges:</Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  Communicating complex designs to non-technical
                                  stakeholders
                                </li>
                                <li className="mt-2">
                                  Costly and time-consuming physical model
                                  creation
                                </li>{" "}
                                <li className="mt-2">
                                  Difficulty in visualizing spaces before
                                  they're built
                                </li>
                              </ul>
                            </Box>{" "}
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">
                                Reality Scale Solutions:
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  Immersive VR walkthroughs of architectural
                                  designs
                                </li>
                                <li className="mt-2">
                                  Real-time collaborative design tools in mixed
                                  reality
                                </li>{" "}
                                <li className="mt-2">
                                  AR visualization of properties and renovations
                                  in existing spaces
                                </li>
                              </ul>
                            </Box>{" "}
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">Benefits:</Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  Improved client communication and faster
                                  approval processes
                                </li>
                                <li className="mt-2">
                                  Significant cost and time savings in the
                                  design phase
                                </li>{" "}
                                <li className="mt-2">
                                  Enhanced property marketing and increased
                                  sales
                                </li>
                              </ul>
                            </Box>{" "}
                          </Grid>
                        </Grid>
                      </Box>
                      {/* decor ends */}
                      {/* automotive advantage start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={6} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography
                                variant="h2"
                                fontWeight={500}
                                fontSize={35}
                              >
                                The Reality Scale Transformation
                              </Typography>{" "}
                              <Typography
                                variant="p"
                                fontWeight={100}
                                color={"#fff"}
                              >
                                Our XR Immersive Solutions are designed to be:
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <ol
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>Intuitive: </b> Minimal training required,
                                  accessible to team members at all technical
                                  levels
                                </li>
                                <li className="mt-2">
                                  <b>Flexible: </b> Modular approach for
                                  customization to meet unique industry needs
                                </li>
                                <li className="mt-2">
                                  <b>Scalable: </b> Solutions that grow with
                                  your business, from startups to global
                                  enterprises
                                </li>
                                <li className="mt-2">
                                  <b>AI-Enhanced: </b> Advanced AI features like
                                  object recognition and predictive analytics
                                </li>
                                <li className="mt-2">
                                  <b>Innovative: </b> Enhanced cross-platform
                                  and cloud experiences leveraging our ecosystem
                                </li>
                                <li className="mt-2">
                                  <b>Future-Proof: </b> Continuous innovation
                                  ensuring your XR solutions evolve with
                                  technology
                                </li>{" "}
                              </ol>
                            </Box>{" "}
                          </Grid>
                          <Grid item xs={6} md={8} lg={6}>
                            <img src={immersivetransformation} width={"100%"} />
                          </Grid>
                        </Grid>
                        <Box
                          sx={{
                            mt: 4,
                          }}
                        >
                          <Typography
                            variant="h2"
                            fontWeight={500}
                            fontSize={35}
                          >
                            Experience the Future Today
                          </Typography>{" "}
                          <Typography variant="p" fontWeight={100}>
                            The XR revolution is here. Reality Scale transforms
                            science fiction concepts into tangible business
                            solutions.
                          </Typography>{" "}
                          <Box>
                            <Typography variant="h6">Imagine:</Typography>
                            <ul
                              style={{
                                color: "#fff",
                                marginTop: "12px",
                              }}
                            >
                              <li>
                                Walking through your product designs before
                                they're built
                              </li>
                              <li className="mt-2">
                                Conducting risk-free training simulations that
                                feel incredibly accurate
                              </li>{" "}
                              <li className="mt-2">
                                Providing customers with experiences that
                                seamlessly blend digital and physical worlds{" "}
                              </li>
                            </ul>
                          </Box>{" "}
                        </Box>
                        <Box
                          sx={{
                            mt: 3,
                          }}
                        >
                          <Typography color={"#fff"} variant="p">
                            Partner with Reality Scale to unlock the full
                            potential of XR technology for your business.
                            Contact us today to discover how our XR Immersive
                            Solutions can drive your industry's innovation,
                            efficiency, and growth.
                          </Typography>
                        </Box>{" "}
                      </Box>
                      {/* automotive advantage ends */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default Immersion;
