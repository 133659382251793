import { Box } from "@mui/material";
import React from "react";
import data from "../../assests/data/data";
import { useNavigate } from "react-router-dom";

import "./index.css";
const RealityScaleProjects = (props) => {
  const navigate = useNavigate();

  const handleRoute = (value) => {
    navigate("/Portfoliodetails", {
      state: { value },
    });
  };

  // const handleRoute = (value) => {
  //   const formattedHeading = value.name.replace(/\s+/g, "-").toLowerCase();
  //   setState(formattedHeading);
  //   navigate(`/Portfoliodetails/${formattedHeading}`, {
  //     state: { value },
  //   });
  // };

  return (
    <Box>
      <div>
        {props && props.value === "All" ? (
          <section id="portfolio">
            <div className="container-fluid mil-portfolio mil-p-120-60 ">
              <div className="row">
                {data.product.map((val, i) => (
                  <div className="col-lg-4">
                    <div
                      className="mil-portfolio-item mil-more mil-parallax mil-mb-60"
                      data-value-1="60"
                      data-value-2="-60"
                      style={{
                        translate: "none",
                        rotate: "none",
                        scale: "none",
                        transform: "translate3d(0px, -32.0873px, 0px)",
                      }}
                    >
                      <div
                        className="mil-cover-frame mil-hori mil-up"
                        onClick={() => handleRoute(val)}
                      >
                        <div
                          className="mil-cover"
                          style={{ cursor: "pointer" }}
                        >
                          <img src={val.img} alt="cover" width={"100%"} />
                        </div>
                      </div>
                      <div className="mil-descr">
                        {/* <div className="mil-labels mil-up mil-mb-15">
                              <div className="mil-label mil-upper mil-accent">
                                {val.type}
                              </div>
                            </div> */}
                        <h4 className="mil-up">{val.name}</h4>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
      </div>

      <div>
        {props && props.value === "VR/AR" ? (
          <section id="portfolio">
            <div className="container mil-portfolio mil-p-120-60 ">
              <div className="row">
                {data.vr.map((val, i) => (
                  <div
                    className="col-lg-4"
                    // data-aos={val.dataAos}
                    // data-aos-offset={val.dataAosOffset}
                    // data-aos-delay={val.dataaosdelay}
                    // data-aos-duration={val.dataaosduration}
                    // data-aos-easing={val.dataaoseasing}
                    // data-aos-mirror={val.dataaosmirror}
                    // data-aos-once={val.dataaosonce}
                    // data-aos-anchor-placement={val.dataaosanchorplacement}
                  >
                    <div
                      className="mil-portfolio-item mil-more mil-parallax mil-mb-60"
                      data-value-1="60"
                      data-value-2="-60"
                      style={{
                        translate: "none",
                        rotate: "none",
                        scale: "none",
                        transform: "translate3d(0px, -32.0873px, 0px)",
                      }}
                    >
                      <div
                        className="mil-cover-frame mil-hori mil-up"
                        onClick={() => handleRoute(val)}
                      >
                        <div
                          className="mil-cover"
                          style={{ cursor: "pointer" }}
                        >
                          <img src={val.img} alt="cover" width={"100%"} />
                        </div>
                      </div>
                      <div className="mil-descr">
                        {/* <div className="mil-labels mil-up mil-mb-15">
                              <div className="mil-label mil-upper mil-accent">
                                {val.type}
                              </div>
                            </div> */}
                        <h4 className="mil-up">{val.name}</h4>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
      </div>

      <div>
        {props && props.value === "Automotive" ? (
          <section id="portfolio">
            <div className="container mil-portfolio mil-p-120-60 ">
              <div className="row">
                {data.automative.map((val, i) => (
                  <div
                    className="col-lg-4"
                    // data-aos={val.dataAos}
                    // data-aos-offset={val.dataAosOffset}
                    // data-aos-delay={val.dataaosdelay}
                    // data-aos-duration={val.dataaosduration}
                    // data-aos-easing={val.dataaoseasing}
                    // data-aos-mirror={val.dataaosmirror}
                    // data-aos-once={val.dataaosonce}
                    // data-aos-anchor-placement={val.dataaosanchorplacement}
                  >
                    <div
                      className="mil-portfolio-item mil-more mil-parallax mil-mb-60"
                      data-value-1="60"
                      data-value-2="-60"
                      style={{
                        translate: "none",
                        rotate: "none",
                        scale: "none",
                        transform: "translate3d(0px, -32.0873px, 0px)",
                      }}
                    >
                      <div
                        className="mil-cover-frame mil-hori mil-up"
                        onClick={() => handleRoute(val)}
                      >
                        <div
                          className="mil-cover"
                          style={{ cursor: "pointer" }}
                        >
                          <img src={val.img} alt="cover" width={"100%"} />
                        </div>
                      </div>
                      <div className="mil-descr">
                        {/* <div className="mil-labels mil-up mil-mb-15">
                              <div className="mil-label mil-upper mil-accent">
                                {val.type}
                              </div>
                            </div> */}
                        <h4 className="mil-up">{val.name}</h4>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
      </div>
      <div>
        {props && props.value === "architecture" ? (
          <section id="portfolio">
            <div className="container mil-portfolio mil-p-120-60 ">
              <div className="row">
                {data.archite.map((val, i) => (
                  <div className="col-lg-4">
                    <div
                      className="mil-portfolio-item mil-more mil-parallax mil-mb-60"
                      data-value-1="60"
                      data-value-2="-60"
                      style={{
                        translate: "none",
                        rotate: "none",
                        scale: "none",
                        transform: "translate3d(0px, -32.0873px, 0px)",
                      }}
                    >
                      <div
                        className="mil-cover-frame mil-hori mil-up"
                        onClick={() => handleRoute(val)}
                      >
                        <div
                          className="mil-cover"
                          style={{ cursor: "pointer" }}
                        >
                          <img src={val.img} alt="cover" width={"100%"} />
                        </div>
                      </div>
                      <div className="mil-descr">
                        {/* <div className="mil-labels mil-up mil-mb-15">
                              <div className="mil-label mil-upper mil-accent">
                                {val.type}
                              </div>
                            </div> */}
                        <h4 className="mil-up">{val.name}</h4>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
      </div>

      {/* <div>
        {props && props.value === "architecture" ? (
          <section id="portfolio">
            <div className="container mil-portfolio mil-p-120-60 ">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h1
                  className="mil-muted mil-mb-60"
                  style={{ color: "#ffd94a" }}
                >
                  Comming{" "}
                  <span className="mil-thins" style={{ color: "white" }}>
                    Soon
                  </span>
                </h1>
              </Box>
            </div>
          </section>
        ) : (
          ""
        )}
      </div> */}

      <div>
        {props && props.value === "Gaming" ? (
          <section id="portfolio">
            <div className="container mil-portfolio mil-p-120-60 ">
              {/* <div className="row justify-content-between align-items-center">
                {data.gaming.map((val, i) => (
                  <div
                    className="col-lg-6"
                    data-aos={val.dataAos}
                    data-aos-offset={val.dataAosOffset}
                    data-aos-delay={val.dataaosdelay}
                    data-aos-duration={val.dataaosduration}
                    data-aos-easing={val.dataaoseasing}
                    data-aos-mirror={val.dataaosmirror}
                    data-aos-once={val.dataaosonce}
                    data-aos-anchor-placement={val.dataaosanchorplacement}
                  >
                    <div
                      className="mil-portfolio-item mil-more mil-parallax mil-mb-60"
                      data-value-1="60"
                      data-value-2="-60"
                      style={{
                        translate: "none",
                        rotate: "none",
                        scale: "none",
                        transform: "translate3d(0px, -32.0873px, 0px)",
                      }}
                    >
                      <div
                        className="mil-cover-frame mil-hori mil-up"
                        onClick={() => handleRoute(val)}
                      >
                        <div
                          className="mil-cover"
                          style={{ cursor: "pointer" }}
                        >
                          <img src={val.img} alt="cover" width={"100%"} />
                        </div>
                      </div>
                      <div className="mil-descr">
                        <div className="mil-labels mil-up mil-mb-15">
                          <div className="mil-label mil-upper mil-accent">
                            {val.type}
                          </div>
                        </div>
                        <h4 className="mil-up">{val.name}</h4>
                      </div>
                    </div>
                  </div>
                ))}
              </div> */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h1
                  className="mil-muted mil-mb-60"
                  style={{ color: "#ffd94a" }}
                >
                  Comming{" "}
                  <span className="mil-thins" style={{ color: "white" }}>
                    Soon
                  </span>
                </h1>
              </Box>
            </div>
          </section>
        ) : (
          ""
        )}
      </div>
    </Box>
  );
};

export default RealityScaleProjects;
