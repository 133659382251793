import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
import { Link } from "react-router-dom";
import ArrowSVG from "../../components/arrow";
import "./index.css";
import Aos from "aos";
import { Box, Typography } from "@mui/material";
import image1 from "../../assests/images/digital_twinimage.webp";

const VideoBanner = () => {
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(null);

  const handleVideoLoad = () => {
    setLoading(false);
  };

  useEffect(() => {
    const preloadVideo = () => {
      const video = document.createElement("video");
      video.src = "https://reality-scale-buck.s3.amazonaws.com/Website+V02.mp4";
      video.preload = "auto";
    };

    preloadVideo();

    let key = localStorage.getItem("loaderShown");
    setLoader(key);
  }, []);

  useEffect(() => {
    if (window.innerWidth > 768) {
      Aos.init();
    }
  }, []);
  return (
    <div>
      <div className="mobile_screen">
        <div className="container-fluid bg-black">
          <div className="container mil-banner mil-dark-bg main_section">
            <div className="mi-invert-fix">
              <div className="mil-animation-frame">
                <div
                  className="mil-animation mil-position-1 mil-scale"
                  data-value-1="6"
                  data-value-2="1.3"
                  style={{
                    transform:
                      "translate3d(0px, 0px, 0px) scale(2.5044, 2.5044)",
                  }}
                >
                  <div className="mil-dodecahedron">
                    {[...Array(12)].map((_, index) => (
                      <div key={index} className="mil-pentagon">
                        {[...Array(5)].map((_, index) => (
                          <div key={index}></div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  className="mil-animation mil-position-2 mil-scale"
                  data-value-1="4"
                  data-value-2="1"
                  style={{
                    transform:
                      "translate3d(0px, 0px, 0px) scale(1.2836, 1.2836)",
                  }}
                >
                  <div className="mil-dodecahedron">
                    {[...Array(12)].map((_, index) => (
                      <div key={index} className="mil-pentagon">
                        {[...Array(5)].map((_, index) => (
                          <div key={index}></div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="container mil-mb-10 bannerdata_section">
                <div className="mil-up" style={{ opacity: 1, zIndex: 999 }}>
                  <div className="container-fluid">
                    <div className="row align-items-center d-flex justify-content-end">
                      <div className="col-md-6 col-lg-12 col-xl-12 ">
                        <div
                          className="banner_section"
                          data-aos="fade-right"
                          data-aos-offset="20"
                          data-aos-delay="50"
                          data-aos-duration="800"
                          data-aos-easing="ease-in-out"
                          data-aos-mirror="true"
                          data-aos-once="false"
                          data-aos-anchor-placement="top"
                        >
                          <h1 className="mil-mutede mil-mb-30 section_heading">
                            Your{" "}
                            <span
                              className="mil-thins"
                              style={{ fontWeight: "100px" }}
                            >
                              Digital
                            </span>
                            <br /> Innovation{" "}
                            <span className="mil-thins">Partner</span>
                          </h1>

                          <p className="mil-light-soft mil-mb-60 text-white section_para ">
                            Step into tomorrow’s world, today- with reinventive
                            and data-driven solutions
                            <br />
                            that define business interactions and customer
                            experience.
                            <br />
                            Transform your venture and vision.
                          </p>
                          <div className="banner_button">
                            <Link
                              to="/contact"
                              className="mil-link mil-muted mil-arrow-place contact_usbutton "
                            >
                              <span> Contact Us</span>
                              <ArrowSVG className="mil-arrow" fill="#000000" />
                            </Link>
                            <Link
                              to="/demoreality"
                              className="mil-button mil-arrow-place mil-btn-space mx-3 demo_button"
                            >
                              <span>Demo Reality</span>
                              <ArrowSVG
                                className="mil-arrow demoarrow_button"
                                fill="#000000"
                              />
                            </Link>
                          </div>
                          <div className="phone_button">
                            <div className="d-flex ">
                              <div className="text-white">
                                {" "}
                                <Link
                                  to="/contact"
                                  className="mil-link mil-muted mil-arrow-place contact_usbutton "
                                >
                                  <span> Contact Us</span>
                                  <ArrowSVG
                                    className="mil-arrow"
                                    fill="#000000"
                                  />
                                </Link>
                              </div>
                              <div className="text-white  dmo_button">
                                {" "}
                                <Link
                                  to="/demoreality"
                                  className="mil-link mil-muted mil-arrow-place dmo_button "
                                >
                                  <span>Demo Reality</span>
                                  <ArrowSVG
                                    className="mil-arrow"
                                    fill="#000000"
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6 col-md-6 col-lg-6">
                        <div>
                          <video
                            autoPlay
                            playsInline
                            loop
                            muted
                            className="video"
                            data-aos="fade-left"
                            data-aos-offset="20"
                            data-aos-delay="50"
                            data-aos-duration="800"
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="false"
                            data-aos-anchor-placement="top"
                          >
                            <source
                              src="https://reality-scale-buck.s3.amazonaws.com/Website%2BV02+(1).mp4"
                              type="video/mp4"
                            />
                          </video>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="desktop_screen">
        <div
          className="container-fluid bg-black"
          style={{
            padding: "8rem ",
          }}
        >
          <div className="container py-5  realityscale_maincantainer">
            <div
              className="row text-aligns-center section_padding"
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            >
              <div className="col-sm-6 d-grid align-items-center">
                <div className="realityscalebanner_text">
                  <h1 className="mil-mutede mil-mb-30 section_heading">
                    <span
                      style={{
                        color: "#ffd94a",
                      }}
                    >
                      Your{" "}
                    </span>
                    <span className="mil-thins" style={{ fontWeight: "100px" }}>
                      Digital
                    </span>
                    <br />
                    <span
                      style={{
                        color: "#ffd94a",
                      }}
                    >
                      Innovation
                    </span>{" "}
                    <span className="mil-thins">Partner</span>
                  </h1>

                  <p className="mil-light-soft text-white section_para ">
                    Step into tomorrow’s world, today- with reinventive and
                    data-driven solutions
                    <br />
                    that define business interactions and customer experience.
                    <br />
                    Transform your venture and vision.
                  </p>
                  <div className="banner_button">
                    <Link
                      to="/contact"
                      className="mil-link mil-muted mil-arrow-place contact_usbutton "
                    >
                      <span> Contact Us</span>
                      <ArrowSVG className="mil-arrow" fill="#000000" />
                    </Link>
                    <Link
                      to="/demoreality"
                      className="mil-button mil-arrow-place mil-btn-space mx-3 demo_button"
                    >
                      <span>Demo Reality</span>
                      <ArrowSVG
                        className="mil-arrow demoarrow_button"
                        fill="#000000"
                      />
                    </Link>
                  </div>
                  <div className="phone_button">
                    <div className="d-flex ">
                      <div className="text-white">
                        {" "}
                        <Link
                          to="/contact"
                          className="mil-link mil-muted mil-arrow-place contact_usbutton "
                        >
                          <span> Contact Us</span>
                          <ArrowSVG className="mil-arrow" fill="#000000" />
                        </Link>
                      </div>
                      <div className="text-white  dmo_button">
                        {" "}
                        <Link
                          to="/demoreality"
                          className="mil-link mil-muted mil-arrow-place dmo_button "
                        >
                          <span>Demo Reality</span>
                          <ArrowSVG className="mil-arrow" fill="#000000" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <video
                  autoPlay
                  playsInline
                  loop
                  muted
                  className="realityscale_videosection"
                  data-aos="fade-left"
                  data-aos-offset="20"
                  data-aos-delay="50"
                  data-aos-duration="800"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-once="false"
                  data-aos-anchor-placement="top"
                >
                  <source
                    src="https://reality-scale-buck.s3.amazonaws.com/Website%2BV02+(1).mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoBanner;
