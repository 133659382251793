import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import "../../style/transform.css";
// import automotiveimage from "../../../assests/images/aboutus.png";
import automotiveimage from "../../assests/images/aboutus.png";
import automotivesolution from "../../assests/transform/automotive/automotivesolution.png";
import automotivesolutionadvantage from "../../assests/transform/automotive/automotiveadvantage.png";
const Automotive = () => {
  return (
    <div className="">
      <Box className="mil-content">
        <div id="swupMain" className="mil-main-transition ">
          <div className="bg-black page_alignment ">
            <div className=" mil-inner-banner">
              <div className="mi-invert-fix">
                <div className="mil-banner-content mil-up">
                  <div className="mil-animation-frame">
                    <div
                      className="mil-animation mil-position-1 mil-scale"
                      data-value-1="7"
                      data-value-2="1.6"
                      style={{
                        transform: "translate3d(0px, 0px, 0px) scale(2, 2)",
                      }}
                    >
                      <div className="mil-dodecahedron">
                        {[...Array(12)].map((_, index) => (
                          <div key={index} className="mil-pentagon">
                            {[...Array(5)].map((_, index) => (
                              <div key={index}></div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="container">
                    <div className="">
                      <ul
                        className="mil-breadcrumbs mil-light mil-mb-60"
                        style={{ padding: "0" }}
                      >
                        <li>
                          <a href="/">Homepage</a>
                        </li>
                        <li>
                          <a href="#">Automotive Details</a>
                        </li>
                      </ul>
                      <h1
                        className="mil-muted mil-mb-60"
                        style={{ color: "#ffd94a" }}
                      >
                        Automotive{" "}
                        <span className="mil-thins" style={{ color: "white" }}>
                          Solution
                        </span>
                        {/* <br /> is finally{" "}
                      <span className="mil-thins" style={{ color: "white" }}>
                        launched
                      </span> */}
                      </h1>
                    </div>
                  </div>
                  <div>
                    <div className="automotive_background"></div>
                  </div>
                  <div className="container">
                    <div
                      style={{
                        marginTop: "5rem",
                      }}
                    >
                      {/* automotive defination start */}
                      <Box>
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={12} md={12} lg={12}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                                // justifyContent: "start",
                              }}
                            >
                              <Typography
                                variant="h2"
                                fontWeight={500}
                                fontSize={35}
                              >
                                Automotive Solutions
                              </Typography>
                              <Box
                                sx={{
                                  mt: 3,
                                }}
                              >
                                <Typography color={"#fff"} variant="p">
                                  Reality Scale leads digital innovation in the
                                  automotive industry, empowering manufacturers
                                  to create exceptional customer experiences Our
                                  cloud-based platform, NeoReal, revolutionizes
                                  the customer journey with high-performance 3D
                                  rendering, AI-driven personalization, and
                                  multi-device accessibility. We offer immersive
                                  technologies like VR showrooms, MR product
                                  demonstrations, and AR mobile applications,
                                  addressing challenges in remote sales and
                                  customization.
                                </Typography>
                              </Box>{" "}
                              <Box
                                sx={{
                                  mt: 2,
                                }}
                              >
                                <Typography color={"#fff"} variant="p">
                                  Our solutions, trusted by leading
                                  manufacturers such as Mahindra, Honda, and
                                  Mercedes, enable deeper customer connections
                                  through interactive visualizations, real-time
                                  configuration, and data-driven insights.
                                  Reality Scale empowers automotive brands to
                                  scale in the digital era.
                                </Typography>
                              </Box>{" "}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      {/* automotive defination ends */}
                      {/* automotive NeoReal Platform start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Box
                          sx={{
                            display: "grid",
                            alignItems: "start",
                          }}
                        >
                          <Typography
                            variant="h2"
                            fontWeight={500}
                            fontSize={35}
                          >
                            NeoReal Platform
                          </Typography>
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography color={"#fff"} variant="p">
                              NeoReal is Reality Scale's flagship cloud
                              platform, revolutionizing how manufacturers,
                              dealerships, and customers interact with vehicles
                              digitally.
                            </Typography>
                          </Box>{" "}
                        </Box>
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={12} md={12} lg={12}>
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">
                                Key Capabilities:
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>High-Fidelity Visualization:</b> Render
                                  vehicles with stunning detail, showcasing
                                  materials, lighting, and reflections for both
                                  exteriors and interiors.
                                </li>
                                <li className="mt-2">
                                  <b>Real-Time Configuration:</b> Instantly
                                  visualize changes in configurations,
                                  accessories, and variants, providing a
                                  responsive and engaging experience.
                                </li>
                                <li className="mt-2">
                                  <b>Multi-Device Accessibility:</b>{" "}
                                  ZenithVision provides a consistent experience
                                  across web, mobile, and tablet platforms,
                                  adapting to various screen sizes.
                                </li>
                                <li className="mt-2">
                                  <b>In-Depth Analytics:</b> Gain valuable
                                  insights into user interactions, preferences,
                                  and customer journeys to inform your business
                                  strategies.
                                </li>
                                <li className="mt-2">
                                  <b>Remote Sales Tools:</b> Conduct virtual
                                  product demonstrations with integrated video
                                  conferencing, enhancing your ability to reach
                                  customers anywhere.
                                </li>
                                <li className="mt-2">
                                  <b>Scalable Architecture:</b> Our cloud-native
                                  design automatically allocates resources for
                                  optimal cost and performance.
                                </li>
                              </ul>
                            </Box>{" "}
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">
                                Customizable Add-ons:
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>AI-Powered Assistance:</b> Implement
                                  virtual assistants and smart configuration
                                  tools to guide customers through their
                                  journey.
                                </li>
                                <li className="mt-2">
                                  <b>Content Creation Suite: </b> Rapidly
                                  produce high-quality marketing assets directly
                                  from the platform.
                                </li>
                                <li className="mt-2">
                                  <b>CRM Integration:</b> Seamlessly connect
                                  with your existing sales pipeline and
                                  analytics tools.
                                </li>
                                <li className="mt-2">
                                  <b>Dynamic Brochures:</b> Generate
                                  personalized marketing materials based on
                                  customer interactions and preferences.
                                </li>
                                <li className="mt-2">
                                  <b>Virtual Dealership:</b> Create immersive,
                                  3D showroom experiences in the metaverse.
                                </li>
                                <li className="mt-2">
                                  <b>Scalable Architecture:</b> Our cloud-native
                                  design automatically allocates resources for
                                  optimal cost and performance.
                                </li>
                              </ul>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      {/* automotive NeoReal Platform ends */}{" "}
                      {/* automotive Immersive technology start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              display: "grid",
                              alignItems: "start",
                              paddingLeft: "17px",
                            }}
                          >
                            <Typography
                              variant="h2"
                              fontWeight={500}
                              fontSize={35}
                            >
                              Immersive Technologies
                            </Typography>
                            <Box
                              sx={{
                                mt: 1,
                              }}
                            >
                              <Typography color={"#fff"} variant="p">
                                We offer a range of complementary immersive
                                technologies to enhance the automotive
                                experience:
                              </Typography>
                            </Box>{" "}
                          </Box>

                          <Grid item xs={12} md={12} lg={12}>
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">
                                Virtual Reality (VR) Showroom{" "}
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>Immersive Exploration:</b> Provide
                                  customers with a fully immersive, 360-degree
                                  virtual experience to explore car models in
                                  detail and customize features.
                                </li>
                                <li className="mt-2">
                                  <b>Inventory Optimization:</b> Showcase your
                                  entire vehicle lineup virtually, eliminating
                                  the need for extensive physical inventory and
                                  maximizing showroom space.
                                </li>
                              </ul>
                            </Box>{" "}
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">
                                Mixed Reality (MR) Product Demonstration
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>Enhanced Showroom Engagement: </b> Overlay
                                  digital information and interactive 3D
                                  elements on physical vehicles, creating
                                  engaging and informative showroom experiences.
                                </li>
                                <li className="mt-2">
                                  <b>Impactful Events and Demonstrations:</b>{" "}
                                  Utilize MR for impactful product launches,
                                  auto shows, and in-depth exploration of
                                  complex vehicle systems.
                                </li>
                              </ul>
                            </Box>{" "}
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">
                                Augmented Reality (AR) Mobile Application
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>Personalized Exploration:</b> Allow
                                  customers to visualize and interact with
                                  life-size 3D car models in their own
                                  environment using their smartphones.
                                </li>
                                <li className="mt-2">
                                  <b>Accessible Customization:</b> Offer
                                  interactive customization and feature
                                  exploration through AR, providing an
                                  accessible and engaging experience anytime,
                                  anywhere.
                                </li>
                              </ul>
                            </Box>{" "}
                            {/* <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">
                                CGI and 3D Modeling Services
                              </Typography>
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>Photorealistic 3D Models:</b> Create
                                  high-quality 3D models of vehicles and
                                  components for marketing, design reviews, and
                                  more.
                                </li>
                                <li className="mt-2">
                                  <b>Custom Automotive Visualizations:</b>{" "}
                                  Develop bespoke visualizations for marketing
                                  campaigns and design reviews, tailored to your
                                  specific needs.
                                </li>
                              </ul>
                            </Box> */}
                          </Grid>
                        </Grid>
                      </Box>
                      {/* automotive Immersive Technology ends */}
                      {/* automotive advantage start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={12} md={12} lg={12}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography
                                variant="h2"
                                fontWeight={500}
                                fontSize={35}
                              >
                                The Reality Scale Advantage
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <Typography variant="h6">
                                Virtual Reality (VR) Showroom{" "}
                              </Typography>
                              <ol
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>Enhanced Customer Engagement:</b> Provide
                                  immersive, interactive experiences that
                                  captivate potential buyers.
                                </li>
                                <li className="mt-2">
                                  <b>Streamlined Sales Process:</b> Empower your
                                  team with tools for faster, more effective
                                  customer interactions.
                                </li>
                                <li className="mt-2">
                                  <b>Optimized Resources:</b> Reduce reliance on
                                  physical inventory and traditional marketing
                                  materials.
                                </li>
                                <li className="mt-2">
                                  <b>Data-Driven Decision Making:</b> Leverage
                                  comprehensive analytics to inform product
                                  development and marketing strategies.
                                </li>
                                <li className="mt-2">
                                  <b>Improved Operational Efficiency:</b>{" "}
                                  Streamline content creation and quickly update
                                  product information across all platforms.
                                </li>
                                <li className="mt-2">
                                  <b>Global Scalability:</b> Easily expand into
                                  new markets with our cloud-based solutions.
                                </li>
                              </ol>
                            </Box>{" "}
                            <Box
                              sx={{
                                mt: 1,
                              }}
                            >
                              <Typography color={"#fff"} variant="p">
                                Partner with Reality Scale to transform your
                                automotive business. Contact us today to
                                discover how we can elevate your brand and drive
                                success in the evolving automotive landscape.
                              </Typography>
                            </Box>{" "}
                          </Grid>
                          {/* <Grid item xs={6} md={8} lg={6}>
                            <img
                              src={automotivesolutionadvantage}
                              width={"100%"}
                            />
                          </Grid> */}
                        </Grid>
                      </Box>
                      {/* automotive advantage ends */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default Automotive;
