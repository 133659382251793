import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import zoom from "../../assests/images/work/zoom.svg";
import ArrowSVG from "../../components/arrow";
import "./index.css";
import { Box, Typography } from "@mui/material";
const FirstDisneyProject = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const [pageData, setPageData] = useState(null);
  useEffect(() => {
    if (location.state) {
      setPageData(location.state.value);
    }
  }, [location.state]);

  return (
    <>
      <div className="mil-content">
        <div id="swupMain" className="mil-main-transition">
          <div className="bg-black mil-dark-bg setion_alignment">
            <div className="mil-inner-banner">
              <div className="mi-invert-fix">
                <div className="mil-banner-content mil-up">
                  <div className="mil-animation-frame">
                    <div
                      className="mil-animation mil-position-1 mil-scale"
                      data-value-1="7"
                      data-value-2="1.6"
                      style={{
                        transform: "translate3d(0px, 0px, 0px) scale(2, 2)",
                      }}
                    >
                      <div className="mil-dodecahedron">
                        {[...Array(12)].map((_, index) => (
                          <div key={index} className="mil-pentagon">
                            {[...Array(5)].map((_, index) => (
                              <div key={index}></div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="container detailssection_alignment page_heading">
                    <ul
                      className="mil-breadcrumbs mil-light mil-mb-60"
                      style={{ padding: "0" }}
                    >
                      <li>
                        <a href="/">Homepage</a>
                      </li>
                      <li>
                        <a href="/portfolio">Portfolio</a>
                      </li>
                      <li>
                        <a href="#">Portfolio Detail's</a>
                      </li>
                    </ul>
                    <h1
                      className="mil-muted mil-mb-60"
                      style={{ color: "#ffd94a" }}
                    >
                      Our{" "}
                      <span className="mil-thins" style={{ color: "white" }}>
                        Portfolio
                      </span>
                      <br /> Detail's{" "}
                      <span
                        className="mil-thins"
                        style={{ color: "white" }}
                      ></span>
                    </h1>
                    <a
                      className="mil-link mil-accent mil-arrow-place mil-down-arrow"
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   document
                      //     .getElementById("target-element")
                      //     .scrollIntoView({ behavior: "smooth" });
                      // }}
                      onClick={() => navigate("/portfolio")}
                    >
                      <ArrowSVG
                        className="mil-arrow back_arrow "
                        fill="#000000"
                      />
                      <span className="text-white ms-3">Back</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="backgroundvlack">
        <section className="mil-p-120-120">
          <div
            className="container-fluid mil-image-frame mil-fw mil-up"
            style={{ transform: "translate(0px, 0px)" }}
            id="target-element"
          >
            <img
              src={
                pageData &&
                pageData.productDetails &&
                pageData.productDetails.bannerImage
              }
              alt="logo"
            />
            {/* <div
              data-fancybox="gallery"
              data-no-swup=""
              className="mil-zoom-btn"
            >
              <img src={zoom} alt="zoom" />
            </div> */}
          </div>

          {/* <ProductDetailsSlider
            data={pageData && pageData.productDetails.slider2}
          /> */}
          <div className="container mt-3 mb-3 detailssection_alignment client_Section">
            <div
              className="mil-info mil-up mainsetion_alignment client_alignment"
              style={{ transform: "translate(0px, 0px)" }}
            >
              <div className="text-white">
                Client: &nbsp;
                <span className="">
                  {pageData &&
                    pageData.productDetails &&
                    pageData.productDetails.client}
                </span>
              </div>

              {pageData &&
              pageData.productDetails &&
              pageData.productDetails.brand === "" ? (
                ""
              ) : (
                <div className="text-white">
                  Brand: &nbsp;
                  <span className="">
                    {pageData &&
                      pageData.productDetails &&
                      pageData.productDetails.brand}
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className="container detailssection_alignment alignmentsection">
            <div className="row">
              <div className="col-sm-5">
                <div className="mainsetion_alignment">
                  <Box>
                    <h2 className="mil-up mil-mb-60">
                      {pageData &&
                        pageData.productDetails &&
                        pageData.productDetails.paraHeading}
                    </h2>
                    <Box>
                      <div className="row">
                        {pageData &&
                          pageData.productDetails &&
                          pageData.productDetails.slider1Image &&
                          pageData.productDetails.slider1Image.map(
                            (val, id) => (
                              <div className="col-sm-6 mt-4" key={id}>
                                <img src={val.img} width="100%" height="100%" />
                              </div>
                            )
                          )}
                      </div>
                    </Box>
                  </Box>
                  <Box
                    className="preview_project"
                    sx={{
                      mt: 3,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    <a
                      className="mil-link mil-accent mil-arrow-place mil-down-arrow"
                      onClick={() => navigate("/portfolio")}
                    >
                      <span className="text-white ">Next Project</span>
                      <ArrowSVG
                        className="mil-arrow next_projectarrow "
                        fill="#000000"
                      />
                    </a>
                  </Box>
                </div>
              </div>
              <div className="col-sm-7">
                <div
                  className="scrollable-section"
                  // style={{
                  //   position: "relative",
                  //   overflowY: "auto",
                  //   maxHeight: "520px",
                  // }}
                >
                  <Box>
                    <div className="container">
                      <div className="row justify-content-between">
                        <div className="col-lg-12 text-white">
                          <Typography
                            variant="h1"
                            fontSize={25}
                            fontWeight={600}
                          >
                            {pageData &&
                              pageData.productDetails &&
                              pageData.productDetails.heading1}
                          </Typography>
                          <p className="mil-up mil-mb-30 mt-3">
                            {pageData &&
                              pageData.productDetails &&
                              pageData.productDetails.para1}
                          </p>
                          <Typography
                            variant="h1"
                            fontSize={25}
                            fontWeight={600}
                          >
                            {pageData &&
                              pageData.productDetails &&
                              pageData.productDetails.heading2}
                          </Typography>
                          <p className="mil-up mt-3">
                            {pageData &&
                              pageData.productDetails &&
                              pageData.productDetails.para2}
                          </p>
                        </div>
                      </div>
                    </div>
                    <Box sx={{ mt: 2 }}></Box>
                    <Box sx={{ my: 6 }}>
                      <div className="container">
                        <div className="row justify-content-between">
                          <div className="col-lg-12 text-white">
                            <Box>
                              <Typography
                                variant="h1"
                                fontSize={25}
                                fontWeight={600}
                              >
                                {pageData &&
                                  pageData.productDetails &&
                                  pageData.productDetails.featureheading}
                              </Typography>
                              <Box sx={{ mt: 2 }}>
                                <ul>
                                  {pageData &&
                                    pageData.productDetails &&
                                    pageData.productDetails.featurelist.map(
                                      (val, id) => (
                                        <li
                                          className="custom-list"
                                          style={{ color: "#d3d3d3" }}
                                          key={id}
                                        >
                                          {val.list}
                                        </li>
                                      )
                                    )}
                                </ul>
                              </Box>
                            </Box>
                            <Box sx={{ my: 4 }}>
                              <Typography
                                variant="h1"
                                fontSize={25}
                                fontWeight={600}
                              >
                                {pageData &&
                                  pageData.productDetails &&
                                  pageData.productDetails.technical}
                              </Typography>
                              <Typography
                                variant="p"
                                fontSize={17}
                                color="grey"
                                fontWeight={400}
                              >
                                {pageData &&
                                  pageData.productDetails &&
                                  pageData.productDetails.technicalsubheading}
                              </Typography>
                              <Box sx={{ mt: 2 }}>
                                <ol>
                                  {pageData &&
                                    pageData.productDetails &&
                                    pageData.productDetails.technicallist.map(
                                      (val, id) => (
                                        <li
                                          className="custom-list"
                                          style={{
                                            color: "#d3d3d3",
                                            marginBottom: "5px",
                                          }}
                                          key={id}
                                        >
                                          {val.head}: {val.list}
                                        </li>
                                      )
                                    )}
                                </ol>
                              </Box>
                            </Box>
                            <Box>
                              <Typography
                                variant="h1"
                                fontSize={25}
                                fontWeight={600}
                              >
                                {pageData &&
                                  pageData.productDetails &&
                                  pageData.productDetails.impact}
                              </Typography>
                              <p className="mil-up mil-mb-30 mt-3">
                                {pageData &&
                                  pageData.productDetails &&
                                  pageData.productDetails.impactberief}
                              </p>
                            </Box>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default FirstDisneyProject;
