import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import "../../style/transform.css";
import automotiveimage from "../../assests/images/aboutus.png";
const Frontier = () => {
  return (
    <div className="">
      <Box className="mil-content">
        <div id="swupMain" className="mil-main-transition ">
          <div className="bg-black mil-dark-bg page_alignment ">
            <div className=" mil-inner-banner">
              <div className="mi-invert-fix">
                <div className="mil-banner-content mil-up">
                  <div className="mil-animation-frame">
                    <div
                      className="mil-animation mil-position-1 mil-scale"
                      data-value-1="7"
                      data-value-2="1.6"
                      style={{
                        transform: "translate3d(0px, 0px, 0px) scale(2, 2)",
                      }}
                    >
                      <div className="mil-dodecahedron">
                        {[...Array(12)].map((_, index) => (
                          <div key={index} className="mil-pentagon">
                            {[...Array(5)].map((_, index) => (
                              <div key={index}></div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="container">
                    <div className="">
                      <ul
                        className="mil-breadcrumbs mil-light mil-mb-60"
                        style={{ padding: "0" }}
                      >
                        <li>
                          <a href="/">Homepage</a>
                        </li>
                        <li>
                          <a href="#">Automotive Details</a>
                        </li>
                      </ul>
                      <h1
                        className="mil-muted mil-mb-60"
                        style={{ color: "#ffd94a" }}
                      >
                        Web3 and{" "}
                        <span className="mil-thins" style={{ color: "white" }}>
                          Blockchain Solutions{" "}
                        </span>
                      </h1>
                      <a
                        href="#"
                        className="mil-link mil-accent mil-arrow-place mil-down-arrow"
                      ></a>
                    </div>
                    <div>
                      {/* automotive defination start */}
                      <Box>
                        <Box
                          sx={{
                            my: 3,
                          }}
                        >
                          <Typography color={"#fff"} variant="p">
                            At Reality Scale, we're pioneering the integration
                            of Web3 and blockchain technologies to create
                            transformative digital ecosystems. Our innovative
                            solutions empower businesses to harness the full
                            potential of decentralised technologies, enhancing
                            security, transparency, and efficiency across
                            various industries.
                          </Typography>
                        </Box>
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={6} md={8} lg={6}>
                            <img src={automotiveimage} width={"100%"} />
                          </Grid>
                          <Grid item xs={6} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                                // justifyContent: "start",
                              }}
                            >
                              <Typography
                                variant="h2"
                                fontWeight={500}
                                fontSize={35}
                              >
                                Blockchain-based Authentication System:
                              </Typography>
                              <Box
                                sx={{
                                  mt: 3,
                                }}
                              >
                                <Typography color={"#fff"} variant="p">
                                  Advanced authentication system leverages
                                  blockchain technology to provide unparalleled
                                  security and identity verification across
                                  digital platforms.
                                </Typography>
                              </Box>{" "}
                              <Box
                                sx={{
                                  mt: 2,
                                }}
                              >
                                <Typography color={"#fff"} variant="h6">
                                  Key Features:
                                </Typography>
                                <ul
                                  style={{
                                    color: "#fff",
                                  }}
                                >
                                  <li>Decentralized identity management</li>
                                  <li>
                                    Multi-factor authentication using blockchain
                                    signatures
                                  </li>
                                  <li>Self-sovereign identity solutions</li>
                                  <li>
                                    Integration with existing identity providers
                                  </li>
                                  <li>Cross-platform identity verification</li>
                                </ul>
                              </Box>{" "}
                              <Box
                                sx={{
                                  mt: 2,
                                }}
                              >
                                <Typography color={"#fff"} variant="h6">
                                  Benefits:
                                </Typography>
                                <ul
                                  style={{
                                    color: "#fff",
                                  }}
                                >
                                  <li>
                                    Enhanced security against identity theft and
                                    fraud
                                  </li>
                                  <li>
                                    Simplified user experience with single
                                    sign-on capabilities
                                  </li>
                                  <li>Improved privacy control for users</li>
                                  <li>
                                    Reduced administrative costs for identity
                                    management
                                  </li>
                                </ul>
                              </Box>{" "}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      {/* automotive defination ends */}{" "}
                      {/* automotive NeoReal Platform start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={6} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography variant="h4" fontWeight={500}>
                                Blockchain-secured Document Management Platform
                              </Typography>
                              <Box
                                sx={{
                                  mt: 3,
                                }}
                              >
                                <Typography color={"#fff"} variant="p">
                                  Transform your document management processes
                                  with our blockchain-powered platform, ensuring
                                  the highest levels of security, authenticity,
                                  and traceability.
                                </Typography>
                              </Box>{" "}
                              <Box
                                sx={{
                                  mt: 2,
                                }}
                              >
                                <Typography color={"#fff"} variant="h6">
                                  Key Features:
                                </Typography>
                                <ul
                                  style={{
                                    color: "#fff",
                                  }}
                                >
                                  <li>
                                    Immutable document versioning and audit
                                    trails
                                  </li>
                                  <li>
                                    Decentralized storage for enhanced security
                                    and accessibility
                                  </li>
                                  <li>
                                    Smart contract-based access control and
                                    permissions
                                  </li>
                                  <li>
                                    Automated compliance and regulatory
                                    reporting
                                  </li>
                                  <li>
                                    Seamless integration with existing document
                                    management systems
                                  </li>
                                </ul>
                              </Box>{" "}
                              <Box
                                sx={{
                                  mt: 2,
                                }}
                              >
                                <Typography color={"#fff"} variant="h6">
                                  Benefits:
                                </Typography>
                                <ul
                                  style={{
                                    color: "#fff",
                                  }}
                                >
                                  <li>
                                    Tamper-proof document integrity and
                                    authenticity
                                  </li>
                                  <li>
                                    Improved collaboration with secure sharing
                                    capabilities
                                  </li>
                                  <li>
                                    Streamlined compliance and audit processes
                                  </li>
                                  <li>
                                    Reduced risk of data breaches and
                                    unauthorised access
                                  </li>
                                </ul>
                              </Box>{" "}
                            </Box>
                          </Grid>
                          <Grid item xs={6} md={8} lg={6}>
                            <img src={automotiveimage} width={"100%"} />
                          </Grid>
                        </Grid>
                      </Box>
                      {/* automotive NeoReal Platform ends */}{" "}
                      {/* automotive Immersive technology start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={6} md={8} lg={6}>
                            <img src={automotiveimage} width={"100%"} />
                          </Grid>
                          <Grid item xs={6} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography variant="h4" fontWeight={500}>
                                Smart Contract Development and Deployment
                                Platform
                              </Typography>
                              <Box
                                sx={{
                                  mt: 3,
                                }}
                              >
                                <Typography color={"#fff"} variant="p">
                                  Empower your business with our comprehensive
                                  smart contract platform, designed to
                                  streamline the creation, testing, and
                                  deployment of blockchain-based agreements.
                                </Typography>
                              </Box>{" "}
                              <Box
                                sx={{
                                  mt: 2,
                                }}
                              >
                                <Typography color={"#fff"} variant="h6">
                                  Key Features:
                                </Typography>
                                <ul
                                  style={{
                                    color: "#fff",
                                  }}
                                >
                                  <li>
                                    Intuitive smart contract development
                                    interface
                                  </li>
                                  <li>
                                    Extensive library of pre-built contract
                                    templates
                                  </li>
                                  <li>Automated testing and auditing tools</li>
                                  <li>Multi-chain deployment options</li>
                                  <li>
                                    Integration with popular blockchain networks
                                  </li>
                                </ul>
                              </Box>{" "}
                              <Box
                                sx={{
                                  mt: 2,
                                }}
                              >
                                <Typography color={"#fff"} variant="h6">
                                  Benefits:
                                </Typography>
                                <ul
                                  style={{
                                    color: "#fff",
                                  }}
                                >
                                  <li>
                                    Accelerated development of secure and
                                    efficient smart contracts
                                  </li>
                                  <li>
                                    Reduced legal and operational costs through
                                    automation
                                  </li>
                                  <li>
                                    Enhanced transparency and trust in business
                                    agreements
                                  </li>
                                  <li>
                                    Flexibility to adapt to various blockchain
                                    ecosystems
                                  </li>
                                </ul>
                              </Box>{" "}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      {/* automotive Immersive Technology ends */}
                      {/* automotive advantage start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={6} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography variant="h4" fontWeight={500}>
                                Tokenization Platform for Real-world Assets
                              </Typography>
                              <Box
                                sx={{
                                  mt: 3,
                                }}
                              >
                                <Typography color={"#fff"} variant="p">
                                  Unlock the potential of your assets with our
                                  advanced tokenization platform, bridging the
                                  gap between physical and digital value.
                                </Typography>
                              </Box>{" "}
                              <Box
                                sx={{
                                  mt: 2,
                                }}
                              >
                                <Typography color={"#fff"} variant="h6">
                                  Key Features:
                                </Typography>
                                <ul
                                  style={{
                                    color: "#fff",
                                  }}
                                >
                                  <li>
                                    Customizable token creation for various
                                    asset classes
                                  </li>
                                  <li>
                                    Regulatory-compliant tokenization processes
                                  </li>
                                  <li>Fractional ownership capabilities</li>
                                  <li>
                                    Secondary market support for token trading
                                  </li>
                                  <li>
                                    Integration with major wallets and exchanges
                                  </li>
                                </ul>
                              </Box>{" "}
                              <Box
                                sx={{
                                  mt: 2,
                                }}
                              >
                                <Typography color={"#fff"} variant="h6">
                                  Benefits:
                                </Typography>
                                <ul
                                  style={{
                                    color: "#fff",
                                  }}
                                >
                                  <li>
                                    Increased liquidity for traditionally
                                    illiquid assets
                                  </li>
                                  <li>
                                    Expanded investor base through fractional
                                    ownership
                                  </li>
                                  <li>
                                    Streamlined asset management and transfer
                                    processes
                                  </li>
                                  <li>
                                    Enhanced transparency and reduced fraud in
                                    asset transactions
                                  </li>
                                </ul>
                              </Box>{" "}
                            </Box>
                          </Grid>
                          <Grid item xs={6} md={8} lg={6}>
                            <img src={automotiveimage} width={"100%"} />
                          </Grid>
                        </Grid>
                      </Box>
                      {/* automotive advantage ends */}
                      {/* automotive advantage start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={6} md={8} lg={6}>
                            <img src={automotiveimage} width={"100%"} />
                          </Grid>
                          <Grid item xs={6} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography variant="h4" fontWeight={500}>
                                Blockchain-powered Supply Chain Management
                                System
                              </Typography>
                              <Box
                                sx={{
                                  mt: 3,
                                }}
                              >
                                <Typography color={"#fff"} variant="p">
                                  Revolutionize your supply chain with our
                                  blockchain-based solution, offering
                                  unprecedented visibility, traceability, and
                                  efficiency.
                                </Typography>
                              </Box>{" "}
                              <Box
                                sx={{
                                  mt: 2,
                                }}
                              >
                                <Typography color={"#fff"} variant="h6">
                                  Key Features:
                                </Typography>
                                <ul
                                  style={{
                                    color: "#fff",
                                  }}
                                >
                                  <li>
                                    End-to-end product tracking and provenance
                                  </li>
                                  <li>
                                    Smart contract-based automation of supply
                                    chain processes
                                  </li>
                                  <li>
                                    Real-time inventory and logistics management
                                  </li>
                                  <li>
                                    Supplier verification and performance
                                    monitoring
                                  </li>
                                  <li>
                                    Integration with IoT devices for data
                                    collection
                                  </li>
                                </ul>
                              </Box>{" "}
                              <Box
                                sx={{
                                  mt: 2,
                                }}
                              >
                                <Typography color={"#fff"} variant="h6">
                                  Benefits:
                                </Typography>
                                <ul
                                  style={{
                                    color: "#fff",
                                  }}
                                >
                                  <li>
                                    Improved transparency and trust across the
                                    supply chain
                                  </li>
                                  <li>
                                    Enhanced product authenticity and reduced
                                    counterfeiting
                                  </li>
                                  <li>
                                    Streamlined logistics and reduced
                                    operational costs
                                  </li>
                                  <li>
                                    Real-time insights for informed
                                    decision-making
                                  </li>
                                </ul>
                              </Box>{" "}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      {/* automotive advantage ends */}{" "}
                      {/* automotive advantage start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={6} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography variant="h4" fontWeight={500}>
                                Web3 Gaming Platform and Infrastructure
                              </Typography>
                              <Box
                                sx={{
                                  mt: 3,
                                }}
                              >
                                <Typography color={"#fff"} variant="p">
                                  Web3 gaming experiences seamlessly blend
                                  blockchain technology with immersive gaming
                                  experiences.
                                </Typography>
                              </Box>{" "}
                              <Box
                                sx={{
                                  mt: 2,
                                }}
                              >
                                <Typography color={"#fff"} variant="h6">
                                  Key Features:
                                </Typography>
                                <ul
                                  style={{
                                    color: "#fff",
                                  }}
                                >
                                  <li>
                                    Decentralized game asset ownership through
                                    NFTs
                                  </li>
                                  <li>
                                    Secure in-game transactions using
                                    cryptocurrency
                                  </li>
                                  <li>
                                    Cross-game interoperability of assets and
                                    achievements
                                  </li>
                                  <li>
                                    Player-driven economies with real-world
                                    value
                                  </li>
                                  <li>
                                    Scalable infrastructure supporting millions
                                    of concurrent users
                                  </li>
                                </ul>
                              </Box>{" "}
                              <Box
                                sx={{
                                  mt: 2,
                                }}
                              >
                                <Typography color={"#fff"} variant="h6">
                                  Benefits:
                                </Typography>
                                <ul
                                  style={{
                                    color: "#fff",
                                  }}
                                >
                                  <li>
                                    Enhanced player engagement through true
                                    digital ownership
                                  </li>
                                  <li>
                                    New revenue streams for game developers and
                                    players
                                  </li>
                                  <li>
                                    Transparent and fair gaming ecosystems
                                  </li>
                                  <li>
                                    Reduced fraud and improved security in
                                    virtual economies
                                  </li>
                                </ul>
                              </Box>{" "}
                            </Box>
                          </Grid>
                          <Grid item xs={6} md={8} lg={6}>
                            <img src={automotiveimage} width={"100%"} />
                          </Grid>
                        </Grid>
                      </Box>
                      {/* automotive advantage ends */}{" "}
                      {/* automotive advantage start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={6} md={8} lg={6}>
                            <img src={automotiveimage} width={"100%"} />
                          </Grid>
                          <Grid item xs={6} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography variant="h4" fontWeight={500}>
                                The Reality Scale Advantage
                              </Typography>
                              <Box
                                sx={{
                                  mt: 3,
                                }}
                              >
                                <Typography color={"#fff"} variant="p">
                                  By choosing Reality Scale for your Web3 and
                                  blockchain needs, you benefit from:
                                </Typography>
                              </Box>{" "}
                              <Box
                                sx={{
                                  mt: 2,
                                }}
                              >
                                <ul
                                  style={{
                                    color: "#fff",
                                  }}
                                >
                                  <li>Cross domain technology integration</li>
                                  <li>Scalable and future-proof solutions</li>
                                  <li>
                                    Expert guidance and support throughout
                                    implementation
                                  </li>
                                  <li>
                                    Seamless integration with existing systems
                                    and processes
                                  </li>
                                  <li>
                                    Continuous innovation to stay ahead in the
                                    rapidly evolving blockchain landscape
                                  </li>
                                </ul>
                              </Box>{" "}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      {/* automotive advantage ends */}
                      <Box
                        sx={{
                          mt: 3,
                        }}
                      >
                        <Typography variant="p" color={"#fff"}>
                          Partner with Reality Scale to unlock the
                          transformative power of Web3 and blockchain
                          technologies for your business. Contact us today to
                          explore how our solutions can drive innovation,
                          enhance security, and create new opportunities in your
                          industry.
                        </Typography>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default Frontier;
